import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  makeStyles,
  Typography,
  Paper,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Card,
  CardContent,
} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  postNewMixDesign,
  putUpdateMixDesign,
  getAssociatedMixesByMixDesignId,
} from '../Data/TSSDataHelpers'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import AddMixSummaryCard from './AddMixSummaryCard'
import ErrorDialog from '../../Common/Components/ErrorDialog'
import { addDataRouteString } from '../Constants/AddDataConstants'
import { handleResNotOk } from '../../Common/Helpers/ErrorHandlers'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import theme from '../../theme/muiTheme'

const useStyles = makeStyles({
  ...theme.customClasses.warningModal,
})

const useStyles2 = makeStyles({
  ...theme.customClasses.successModal,
})

AddMixConfirmDialog.propTypes = {
  /** List all mix details, state object on AddMixDesignView. It populates the card summary. */
  mixDetails: PropTypes.object.isRequired,
  /** Details of the Mix Groups. */
  mixGroupDetails: PropTypes.array,
  /** Array containing all materials entered in the table. */
  materials: PropTypes.array.isRequired,
  /** ID of mix design being updated. */
  editMixId: PropTypes.string,
  /** Sets whether the dialog is open or not. */
  open: PropTypes.bool.isRequired,
  /** Toggles the dialog off if user cancels. */
  setOpen: PropTypes.func.isRequired,
}

/** Call post or put API depending on whether the page is in edit mode, or associated mix is selected. */
export const sendMixDesignData = async (mixDesign, editMixId) => {
  if (editMixId == null) {
    return postNewMixDesign(mixDesign)
  } else {
    mixDesign.mixDesignId = editMixId
    return putUpdateMixDesign(mixDesign)
  }
}

/** Matches Plant, Mix Code, and Condition to determine whether or not a date is required */
export const compareMixes = (newMixObject, mixGroupArray, editMixId) => {
  const mixCode = 'mixCode'
  const plantId = 'plantId'
  const isCO2Design = 'isCO2Design'
  const mixDesignId = 'mixDesignId'
  const editId = Number(editMixId)
  if (!mixGroupArray) return
  return mixGroupArray.some(
    item =>
      newMixObject[mixCode] === item[mixCode] &&
      newMixObject[plantId] === item[plantId] &&
      newMixObject[isCO2Design] === item[isCO2Design] &&
      editId !== item[mixDesignId]
  )
}

/** Creates an object of Mix Associations for Active Mix selection */
export const getActiveMixChoices = (arr, condition, editMixId) => {
  // Creates a selection list of Mix Associations that are both active, and of the same condition.
  let newArray = arr.filter(
    obj => obj['isActiveMixGroup'] === true && obj['isCO2Design'] === condition
  )
  if (editMixId) {
    newArray = newArray.filter(obj => obj['mixDesignId'] !== Number(editMixId))
  }
  return newArray
}

export const getActiveMix = arr => {
  let newArray = arr.filter(obj => obj['isActiveMixGroup'] === true)
  return newArray[0]?.mixCode
}

export const setDefaultActiveMix = (activeMixValue, postObj) => {
  if (!activeMixValue) {
    postObj.isActiveMixGroup = true
  } else {
    postObj.isActiveMixGroup = activeMixValue
  }
}

/**
 * This component shows users the entered data prior to submitting, it is managed from AddMixDesignView
 * @param {Object} mixDetails - list all mix details, state object on AddMixDesignView. It populates the card summary.
 * @param {Array} materials - array containing all materials entered in the table.
 * @param {Number} editMixId - ID of mix design being updated.
 * @param {Boolean} open - sets whether the dialog is open or not.
 * @param {Function} setOpen - toggles the dialog off if user cancels.
 * @param {Function} setHasUserInput - toggles the modal off if new mix saved successfully.
 */
function AddMixConfirmDialog(props) {
  const warningClass = useStyles()
  const successClass = useStyles2()
  const {
    postObject,
    mixDetails,
    mixGroupDetails,
    parentMixAssociation,
    materials,
    editMixId,
    open,
    setOpen,
    setHasUserInput,
    isActiveMixGroupValue,
  } = props

  /** Error message returned from Kelowna */
  const [errorMessage, setErrorMessage] = useState()
  /** The Radio Button Selection value */
  const [formValue, setFormValue] = useState(null)
  /** The selected Mix Association */
  const [selectedMixAssociation, setSelectedMixAssociation] = useState()
  /** The mixes you can select to be Active  */
  const [mixAssociationChoices, setMixAssociationChoices] = useState()
  /** The Mix Code that is currently active */
  const [activeMixCode, setActiveMixCode] = useState('')
  /** For the Dialog Display. This helps maintain the Radio Button Label info as the dialog closes */
  const [postObjectDetails, setPostObjectDetails] = useState({})
  /** Determines if the Active Mix Choice shows up in the Dialog */
  const [showActiveMixDisplay, setShowActiveMixDisplay] = useState(false)
  /** The date used to update when a mix was last used */
  const [selectedDate, setSelectedDate] = useState(null)
  /** Determines whether the date/timestamp of a mix needs to be updated */
  const [isDateRequired, setIsDateRequired] = useState(false)
  /** Handles success confirmation after adding mix */
  const [confirmed, setConfirmed] = useState(false)

  /** History hook to redirect user after successful data entry. */
  const history = useHistory()

  /** Form Validation Hook for Date Picker */
  const { handleSubmit, register, errors } = useForm()

  /* Save new mix in DB. */
  const handleSave = async () => {
    sendMixDesignData(postObject, editMixId).then(res => {
      // Redirect to add data page if batch was added.
      if (res.ok) {
        setHasUserInput(false)
        setConfirmed(true)
        // Display Confirmed notification for 3 seconds before redirect
        setTimeout(() => {
          setConfirmed(false)
          history.push(addDataRouteString)
        }, 2000)
      } else handleResNotOk(res, setErrorMessage)
    })
  }

  /* Closes Confirm Dialog and Confirmation display. */
  const handleClose = () => {
    setOpen(false)
    setFormValue(null)
    setConfirmed(false)
  }

  /** Updates properties of the Mix that will be set to Active based on selection. */
  const handleFormChange = event => {
    // Update Radio Button Selection View
    setFormValue(event)
    if (typeof event === 'number') {
      // If active mix selection (event) is a number, then the value is a mixDesignId
      postObject.activeMixGroupId = event
      postObject.isActiveMixGroup = false
    } else {
      // If active mix selection (event) is not a number, then the value is a mixCode
      postObject.activeMixGroupId = null
      postObject.isActiveMixGroup = true
    }
  }

  /** Adds the date to the postObject */
  const handleDateChange = newDate => {
    setSelectedDate(newDate)
    // Checks if newDate has a Valid Date Format before adding to postObject. Removes timestamp otherwise.
    if (newDate instanceof Date && !isNaN(newDate.valueOf())) {
      postObject.timestamp = newDate.toISOString()
    } else if (postObject.timestamp) {
      delete postObject.timestamp
    }
  }

  /** Gets details of the selected Associated Mix Group and updates the Summary Card information **/
  useEffect(() => {
    // Get the Mix Design ID of the selected Associated Mix from the Mix Group
    if (mixDetails.associatedMix) {
      const mixGroup = mixGroupDetails.filter(function(mix) {
        return mix.mixDesignId === Number(mixDetails.associatedMix.mixDesignId)
      })
      // Get associated mix details from mix group
      const [selectedAssociatedMixDetails] = mixGroup
      setSelectedMixAssociation(selectedAssociatedMixDetails)
    } else {
      // Clear associated mix and remove date requirement if Associated Mix is not selected in dropdown
      setSelectedMixAssociation()
    }
  }, [mixGroupDetails, mixDetails.associatedMix, postObject])

  /** Get other Mix Associations and Auto-Select Parent Mix Association when editing a mix. **/
  useEffect(() => {
    if (!mixDetails.associatedMix) return
    let allassociatedMixes
    allassociatedMixes = {
      mixDesignId: mixDetails.associatedMix.mixDesignId,
    }
    // Get Associated Mixes
    getAssociatedMixesByMixDesignId(allassociatedMixes)
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            const { results } = data
            setMixAssociationChoices(
              getActiveMixChoices(results, postObject.isCO2Design, editMixId)
            )
          })
      })
      .catch(e => console.log(e))
  }, [
    mixDetails,
    postObject.isCO2Design,
    editMixId,
    parentMixAssociation,
    postObject,
  ])

  /** Set default properties, and Mix Association ID for the postObject. **/
  useEffect(() => {
    if (!postObject) return
    if (selectedMixAssociation) {
      // Add Parent Mix Design ID (Selected Associated Mix) to Post Object
      postObject.parentMixDesignId = selectedMixAssociation.mixDesignId
      setDefaultActiveMix(isActiveMixGroupValue, postObject)
    } else {
      postObject.parentMixDesignId = null
      setDefaultActiveMix(isActiveMixGroupValue, postObject)
    }
  }, [postObject, selectedMixAssociation, isActiveMixGroupValue])

  /** Check if date is required and set default value for Active Mix Selection */
  useEffect(() => {
    if (postObject) {
      // Pass the postObject details into state for displaying info on Dialog
      setPostObjectDetails(postObject)
      if (mixGroupDetails) {
        setIsDateRequired(compareMixes(postObject, mixGroupDetails, editMixId))
      }
    }
  }, [postObject, mixGroupDetails, editMixId])

  /** This sets the Mix Code of the active mix for the Dialog info
   * and determines if Active Mix Selection is displayed. */
  useEffect(() => {
    if (!mixAssociationChoices || !selectedMixAssociation) return
    setActiveMixCode(getActiveMix(mixAssociationChoices))
    const mixChanged =
      parentMixAssociation?.mixDesignId !== selectedMixAssociation?.mixDesignId
    const enoughMixes = mixAssociationChoices.length > 0
    if (!editMixId) {
      setShowActiveMixDisplay(enoughMixes)
    } else {
      setShowActiveMixDisplay(mixChanged && enoughMixes)
    }
  }, [
    mixAssociationChoices,
    editMixId,
    selectedMixAssociation,
    parentMixAssociation,
  ])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      fullWidth
      maxWidth="sm"
    >
      <Paper className={warningClass.modal} elevation={1} spacing={2}>
        {!confirmed ? (
          <Grid
            container
            item
            justify="space-between"
            className={warningClass.headerBackground}
          >
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item>
                <ReportProblemOutlinedIcon className={warningClass.icon} />
              </Grid>
              <Grid item>
                {showActiveMixDisplay ? (
                  <Typography variant="h3" className={warningClass.fontColor}>
                    <strong>Confirm:</strong> Active Mix Group
                  </Typography>
                ) : (
                  <Typography variant="h3" className={warningClass.fontColor}>
                    <strong>Confirm:</strong> Mix Design
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            item
            justify="space-between"
            className={successClass.headerBackground}
          >
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item>
                <CheckCircleOutlineOutlinedIcon className={successClass.icon} />
              </Grid>
              <Grid item>
                <Typography variant="h3" className={successClass.fontColor}>
                  <strong>Confirmed</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Collapse in={!confirmed}>
          <Grid container direction="column" justify="center" spacing={2}>
            {showActiveMixDisplay ? (
              <Grid item xs={12}>
                <DialogContent>
                  <Typography className={warningClass.fontColor}>
                    <strong>
                      Associated mix {activeMixCode} is currently the Active CO2
                      Mix
                    </strong>
                    <br />
                    Select the mix group you would like to be labelled as
                    active. The mix group not selected will be labelled as
                    archived.
                  </Typography>
                  <Card elevation={1}>
                    <CardContent>
                      <FormControl style={{ alignItems: 'center' }}>
                        <RadioGroup row>
                          {mixAssociationChoices?.map(mixAssociation => (
                            <FormControlLabel
                              key={mixAssociation.mixDesignId}
                              value={mixAssociation.mixDesignId}
                              onChange={e => {
                                handleFormChange(Number(e.target.value))
                              }}
                              checked={formValue === mixAssociation.mixDesignId}
                              control={<Radio color="primary" />}
                              label={
                                <Typography>
                                  <strong>
                                    Mix Group {mixAssociation?.mixCode}
                                  </strong>
                                  <br />
                                  CO2 Dosage: {mixAssociation?.baseLineCO2}
                                  <br />
                                  Cement Reduction:{' '}
                                  {mixAssociation?.baseLineCementReduction}
                                </Typography>
                              }
                            />
                          ))}
                          <FormControlLabel
                            key={postObjectDetails.mixCode}
                            value={postObjectDetails.mixCode}
                            checked={formValue === postObjectDetails.mixCode}
                            onChange={e => {
                              handleFormChange(e.target.value)
                            }}
                            control={<Radio color="primary" />}
                            label={
                              <Typography>
                                <strong>
                                  Mix Group {postObjectDetails?.mixCode}
                                </strong>
                                <br />
                                CO2 Dosage: {postObjectDetails?.baseLineCO2}
                                <br />
                                Cement Reduction:{' '}
                                {postObjectDetails?.baseLineCementReduction}
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </CardContent>
                  </Card>
                </DialogContent>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <DialogContent>
                  <Typography
                    className={warningClass.fontColor}
                    style={{ paddingBottom: 8 }}
                  >
                    <strong>Please review and confirm your changes.</strong>
                  </Typography>
                  <AddMixSummaryCard
                    mixDetails={mixDetails}
                    materials={materials}
                    hideButton
                  />
                </DialogContent>
              </Grid>
            )}
            {isDateRequired && (
              <Grid item xs={12}>
                <DialogContent>
                  <Card elevation={1}>
                    <CardContent>
                      <Typography>
                        The mix you are adding{' '}
                        <strong>requires a start date</strong> for when it was
                        in use. Please select a date below.
                      </Typography>
                      <KeyboardDatePicker
                        name="date"
                        label="Start Date"
                        format="yyyy-MM-dd"
                        placeholder="YYYY-MM-DD"
                        onChange={date => handleDateChange(date)}
                        value={selectedDate}
                        inputVariant="outlined"
                        size="small"
                        inputRef={register({ required: isDateRequired })}
                        error={!!errors.date}
                        helperText={
                          errors.date
                            ? '*Select a start date for the archive mix your are adding.'
                            : ''
                        }
                      />
                    </CardContent>
                  </Card>
                </DialogContent>
              </Grid>
            )}
          </Grid>
          <DialogActions>
            <Grid
              container
              justify="flex-end"
              spacing={2}
              className={warningClass.gridContainer}
              style={{ paddingTop: 30 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  className={warningClass.outlinedWarningButton}
                  onClick={handleClose}
                >
                  No, Go Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="warning"
                  className={warningClass.containedWarningButton}
                  onClick={handleSubmit(handleSave)}
                  disabled={showActiveMixDisplay && !formValue}
                >
                  Yes, Confirm Changes
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Collapse>
        {errorMessage && (
          <ErrorDialog
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        )}
      </Paper>
    </Dialog>
  )
}

export default AddMixConfirmDialog
