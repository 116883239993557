import React from 'react'
import { makeStyles, Card, CardContent, Typography } from '@material-ui/core'
import theme from '../../../theme/muiTheme'
import {
  DialogModalSize,
  DialogModalType,
  IDigestedMaterial,
  MaterialSelectType,
  MaterialSubTypeOptions,
  MergeMaterialType,
} from '../../Logic/Types'
import DialogModal from '../../../Common/Components/DialogModal/DialogModal'
import MaterialSelect from '../MaterialSelect'

const useStyles = makeStyles({
  // @ts-ignore ts won't acknowledge customClasses being added to theme
  ...theme.customClasses.warningModal,
  icon: {
    // @ts-ignore
    ...theme.customClasses.warningModal.icon,
    fontSize: '24px',
  },
  headerBackground: {
    // @ts-ignore
    ...theme.customClasses.warningModal.headerBackground,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  gridContainer: {
    // @ts-ignore
    ...theme.customClasses.warningModal.gridContainer,
    paddingRight: '0em',
    paddingBottom: '1em',
  },
  cancelButton: {
    // @ts-ignore
    ...theme.customClasses.warningModal.outlinedWarningButton,
  },
  confirmButton: {
    // @ts-ignore
    ...theme.customClasses.warningModal.containedWarningButton,
  },
})

const useStyles2 = makeStyles({
  // @ts-ignore
  ...theme.customClasses.successModal,
  icon: {
    // @ts-ignore
    ...theme.customClasses.successModal.icon,
    fontSize: '24px',
  },
  headerBackground: {
    // @ts-ignore
    ...theme.customClasses.successModal.headerBackground,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
})

export interface IMergeMaterialModalPresentationalProps {
  /** Determines if modal is open */
  modalOpen: boolean
  /** The type of the modal (Merge, Edit, Success etc.) */
  modalType: MergeMaterialType | undefined
  /** Current material being viewed */
  currentMaterial: IDigestedMaterial
  /** Handles the modal cancel button functionality */
  handleCancel: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Handles the modal Edit button functionality */
  handleEdit: (id: number) => void
  /** Handles the modal Merge button functionality */
  handleMerge: () => void
  /** Array of non-ingested materials with the same subtype as current material */
  mergeableMaterials: Array<IDigestedMaterial>
  /** Selected material to merge with */
  selectedMaterial: IDigestedMaterial | undefined
  /** Set state when a material is selected from dropdown */
  setSelectedMaterial: (material: IDigestedMaterial | undefined) => void
  /** Boolean for loading state */
  isLoading: boolean
}

function MergeMaterialModalPresentational(
  props: IMergeMaterialModalPresentationalProps
) {
  const {
    modalOpen,
    modalType,
    currentMaterial,
    isLoading,
    handleCancel,
    handleEdit,
    handleMerge,
    mergeableMaterials,
    selectedMaterial,
    setSelectedMaterial,
  } = props

  const warningClass = useStyles()
  const successClass = useStyles2()

  const materialSubtype = Object.entries(MaterialSubTypeOptions).find(
    ([key, value]) => key === currentMaterial?.materialType
  )?.[1]
  const materialName = currentMaterial?.aliases?.[0] ?? materialSubtype
  const mergedAliases = [
    ...(selectedMaterial?.aliases ?? []),
    ...(currentMaterial?.aliases ?? []),
  ]

  let headerText, contentText, cancelButtonText, confirmButtonText

  switch (modalType) {
    case MergeMaterialType.MERGE:
      headerText = 'Merging Material'
      contentText = `You are about to merge a material ${materialName}. Select the Material
      you would like to merge with:`
      cancelButtonText = 'No, Cancel'
      confirmButtonText = 'Yes, Merge Material'
      break
    case MergeMaterialType.EDIT:
      headerText = 'No Relevant Materials to Merge'
      contentText =
        'There are no relevant materials to merge. If you have additional information for this material please add it to this entry. Do you want to edit the material?'
      cancelButtonText = 'No, Cancel'
      confirmButtonText = 'Yes, Edit Material'
      break
    default:
      headerText = 'Your Material Has Been Merged'
      contentText = `${materialName} has been successfully merged.`
      cancelButtonText = ''
      confirmButtonText = ''
  }

  const mergeCardContent = (
    <Card elevation={1}>
      <CardContent>
        <MaterialSelect
          type={MaterialSelectType.MergeMaterial}
          isViewOnly={isLoading}
          materials={mergeableMaterials}
          selected={selectedMaterial}
          handleChange={setSelectedMaterial}
          hasCheckBox={false}
        />
      </CardContent>
    </Card>
  )

  const successCardContent = (
    <Card elevation={1} style={{ marginBottom: '2em' }}>
      <CardContent>
        {mergedAliases.length > 0 ? (
          <Typography className={successClass.fontColor}>
            {mergedAliases.map((string: string, index: number) => (
              <React.Fragment key={`${string}-${index}`}>
                {string}
                {index !== mergedAliases.length - 1 && ', '}
              </React.Fragment>
            ))}
          </Typography>
        ) : (
          <Typography className={successClass.fontColor}>No alias</Typography>
        )}
        <Typography className={successClass.fontColor}>
          Subtype: {materialSubtype} / Supplier Company:{' '}
          {selectedMaterial?.supplierCompany ?? 'null'}
        </Typography>
      </CardContent>
    </Card>
  )

  return (
    <>
      {modalType === MergeMaterialType.MERGE && (
        <DialogModal
          modalOpen={modalOpen}
          modalType={DialogModalType.warning}
          modalSize={DialogModalSize.Small}
          headerText={headerText}
          contentText={contentText}
          handleCancel={handleCancel}
          handleConfirm={handleMerge}
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          parentClasses={warningClass}
          hasAction={true}
          hasCard={true}
          shouldDisable={!selectedMaterial}
          isLoading={isLoading}
          cardContent={mergeCardContent}
        />
      )}
      {modalType === MergeMaterialType.EDIT && (
        <DialogModal
          modalOpen={modalOpen}
          modalType={DialogModalType.warning}
          modalSize={DialogModalSize.Small}
          headerText={headerText}
          contentText={contentText}
          handleCancel={handleCancel}
          handleConfirm={() => handleEdit(currentMaterial.materialMappingId)}
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          parentClasses={warningClass}
          hasAction={true}
          hasCard={false}
        />
      )}
      {modalType === MergeMaterialType.SUCCESS && (
        <DialogModal
          modalOpen={modalOpen}
          modalType={DialogModalType.success}
          modalSize={
            mergedAliases.length > 5
              ? DialogModalSize.Medium
              : DialogModalSize.Small
          }
          headerText={headerText}
          contentText={contentText}
          parentClasses={successClass}
          hasAction={false}
          hasCard={true}
          cardContent={successCardContent}
        />
      )}
    </>
  )
}

export default MergeMaterialModalPresentational
