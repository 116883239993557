import React, { useEffect, useState } from 'react'
import { Link, useRouteMatch, useLocation } from 'react-router-dom'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  makeStyles,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  Add,
  ArrowBack,
  FiberManualRecord,
  Visibility,
} from '@material-ui/icons'
import isEqual from 'lodash.isequal'
import {
  getUrlFromFilters,
  getMixGroupByBatch,
  getAssociatedMixesByMixDesignId,
  getNewSettings,
} from '../Data/TSSDataHelpers'
import { getDivisionNames } from '../../Common/Helpers/DataHelpers'
import {
  digestMixDesigns,
  getEditOrViewBtnLabel,
  tssCanWrite,
  findMixDesignById,
} from '../Logic/TSSLogic'
import {
  getBaleenVariationIdsUrl,
  getBatchUrl,
} from '../Logic/AddDataViewLogic'
import { atomCurrentCustomer, atomJWT } from '../../Common/atoms'
import { atomCustomerOptions } from '../../Common/tssAtomsB'
import {
  isAbortedFetchError,
  removeActiveFilter,
  addTotalCylinders,
} from '../../Common/Helpers/GeneralHelpers'
import {
  SimpleTableTypes,
  FilterComponentTypes,
} from '../../Common/Logic/Types'
import AddDataHighLevelSummary from '../Components/AddDataHighLevelSummary'
import AddDataBatchSamplesTableLogical from '../Components/AddDataBatchSamplesTable/AddDataBatchSamplesTableLogical'
import SimpleTablePresentational from '../../Common/Components/SimpleTable/SimpleTablePresentational'
import MixGroupVariationTable from '../Components/MixGroupVariationTable/MixGroupVariationTable'
import { addStyles, headCellsAddDataView } from '../Constants/AddDataConstants'
import { baseColors } from '../../theme/colors'
import FilterPanelLogical from '../../Common/Components/FilterPanel/FilterPanelLogical'
import FilterButton from '../../Common/Components/FilterPanel/FilterButton'
import FilterPanelChips from '../../Common/Components/FilterPanel/FilterPanelChips'
import cloneDeep from 'lodash.clonedeep'
import theme from '../../theme/muiTheme.js'
import { useFilterPanelOpen } from '../Logic/Hooks'
import { handleChipLabel, mapSettingsToParams } from '../Helpers/AddDataHelpers'

const useStyles = makeStyles({
  legend: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  floatingMetricSwitch: {
    position: 'absolute',
    right: '-0.5em',
  },
  //Filter Panel Styling
  ...theme.customClasses.filterPanel,
  filterPanelOpen: {
    ...theme.customClasses.filterPanel.filterPanelOpen,
    top: '150px',
  },
  filterPanel: {
    ...theme.customClasses.filterPanel.filterPanel,
    top: '150px',
  },
  ...addStyles(SimpleTableTypes.AddDataMixGroup, theme, []),
})

const useFiltersStyles = makeStyles({
  checkbox: {
    marginLeft: 'auto',
  },
  checkboxFormControlLabel: {
    marginLeft: 'auto',
    width: '100%',
  },
})

const defaultMixTableData = {
  digestedMixDesigns: [],
  selectedMixDesigns: [],
  selectedMixVariations: [],
  batchActuals: [],
  selectedBatchActuals: [],
  dataToExport: [],
}

const defaultTableSettings = {
  page: 0,
  rowsPerPage: 50,
  order: 'asc',
  orderBy: 'mixCode',
}

const defaultMetrics = {
  count: 0,
  numCO2Samples: 0,
  numNonCO2Samples: 0,
}

function AddDataView() {
  const classes = useStyles()
  const filterClasses = useFiltersStyles()
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [isAssociationView, setIsAssociationView] = useState(false)
  const currentCustomer = useRecoilValue(atomCurrentCustomer)
  const [mixDesignTableData, setMixDesignTableData] = useState(
    defaultMixTableData
  )
  const [associatedMixTableData, setAssociatedMixTableData] = useState(
    defaultMixTableData
  )
  const [mixDesignTableSettings, setMixDesignTableSettings] = useState(
    defaultTableSettings
  )
  const [associatedMixTableSettings, setAssociatedMixTableSettings] = useState(
    defaultTableSettings
  )
  const [mixDesignMetrics, setMixDesignMetrics] = useState(defaultMetrics)
  const [addDataSettings, setAddDataSettings] = useState({
    divisionId: currentCustomer.division?.divisionId
      ? [currentCustomer.division?.divisionId]
      : [],
    plantId: currentCustomer.plant?.plantId
      ? [currentCustomer.plant?.plantId]
      : [],
    mixCode: [],
    isCO2Design: [],
    minNumSamples: [],
    maxNumSamples: [],
    mixDesignId: [],
    showArchived: ['true'],
    includeOnlyMissingDesignStrength: [],
    includeOnlyMissingMixAssociation: [],
  })

  const [customerOptions, setCustomerOptions] = useRecoilState(
    atomCustomerOptions
  )
  const [mixCodeInternal, setMixCodeInternal] = useState(
    addDataSettings.mixCode.length > 0 ? addDataSettings.mixCode[0] : ''
  )
  const [conditionInternal, setConditionInternal] = useState(
    addDataSettings.isCO2Design.length > 0
      ? addDataSettings.isCO2Design[0]
      : 'all'
  )
  const [minSamples, setMinSamples] = useState(
    addDataSettings.minNumSamples.length > 0
      ? addDataSettings.minNumSamples[0]
      : ''
  )
  const [maxSamples, setMaxSamples] = useState(
    addDataSettings.maxNumSamples.length > 0
      ? addDataSettings.maxNumSamples[0]
      : ''
  )
  const [sliderValues, setSliderValues] = useState([minSamples, maxSamples])
  const JWT = useRecoilValue(atomJWT)
  const { url } = useRouteMatch()
  const { search } = useLocation()

  const tableData = isAssociationView
    ? associatedMixTableData
    : mixDesignTableData
  const setTableData = isAssociationView
    ? setAssociatedMixTableData
    : setMixDesignTableData
  const tableSettings = isAssociationView
    ? associatedMixTableSettings
    : mixDesignTableSettings
  const setTableSettings = isAssociationView
    ? setAssociatedMixTableSettings
    : setMixDesignTableSettings
  const selectedBatchActuals = isAssociationView
    ? associatedMixTableData.selectedBatchActuals
    : mixDesignTableData.selectedBatchActuals

  /** Boolean to determine when to show loading spinner */
  const [isLoading, setIsLoading] = useState(false)
  /** Handles opening and closing of Filter Drawer */
  const [open, setOpen] = useState(false)
  /** Tracks how many filters are currently set */
  const [filterCount, setFilterCount] = useState(0)
  /** Tracks the currently active filters for Filter Chip display */
  const [activeFilters, setActiveFilters] = useState([])
  /**  */
  const [dataSummaryFlag, setDataSummaryFlag] = useState(false)

  const isChipActive = option => {
    const optionName = option.name
    if (optionName === 'Design Str.') {
      return addDataSettings.includeOnlyMissingDesignStrength[0] === 'true'
    } else if (optionName === 'Mix Association') {
      return addDataSettings.includeOnlyMissingMixAssociation[0] === 'true'
    } else {
      return
    }
  }

  /** A set of variables used for passing down into FilterPanelLogical  */
  const addDataHelperVariables = {
    inputsAlwaysEnabled: true,
    mixCodeInternal: mixCodeInternal,
    setMixCodeInternal: setMixCodeInternal,
    conditionInternal: conditionInternal,
    setConditionInternal: setConditionInternal,
    minSamples: minSamples,
    setMinSamples: setMinSamples,
    maxSamples: maxSamples,
    setMaxSamples: setMaxSamples,
    sliderValues: sliderValues,
    setSliderValues: setSliderValues,
    isLocationRequired: false,
    isChipActive: isChipActive,
  }

  const handleClickChip = (_filter, option) => {
    const optionName = option.name
    const newSettings = cloneDeep(addDataSettings)
    if (optionName === 'Design Str.' || optionName === 'Mix Association') {
      const filterKey =
        optionName === 'Design Str.'
          ? 'includeOnlyMissingDesignStrength'
          : 'includeOnlyMissingMixAssociation'

      if (newSettings[filterKey][0] === 'true') {
        newSettings[filterKey] = []
      } else {
        newSettings[filterKey][0] = 'true'
      }
    }

    setAddDataSettings(newSettings)
  }

  const handleShowArchivedChange = () => {
    setAddDataSettings(prevAddDataSettings => {
      const showArchivedVal =
        prevAddDataSettings.showArchived[0] === 'true' ? 'false' : 'true'
      return {
        ...prevAddDataSettings,
        showArchived: [showArchivedVal],
      }
    })
  }

  const handleHasSamplesChange = () => {
    setAddDataSettings(prevAddDataSettings => {
      let newMinNumSamples
      const isChecked =
        prevAddDataSettings.minNumSamples[0] === '1' &&
        !prevAddDataSettings.maxNumSamples[0]
      if (isChecked) {
        newMinNumSamples = []
        setMinSamples('')
        setSliderValues(['', maxSamples])
      } else {
        newMinNumSamples = ['1']
        setMinSamples('1')
        setSliderValues(['1', ''])
      }

      return {
        ...prevAddDataSettings,
        minNumSamples: newMinNumSamples,
      }
    })
  }

  const isShowArchivedDisabled = () => {
    return isAssociationView
  }

  /** Determines the filters that will appear */
  const filterPanel = [
    {
      name: 'CUSTOMER',
      category: 'customer',
      filters: [
        {
          property: 'producer',
          name: 'Producer',
          componentType: 'CustomerAndLocationSelect',
        },
      ],
    },
    {
      name: 'MIX DETAILS',
      category: 'mixDetails',
      filters: [
        {
          property: 'mixCode',
          name: 'Mix Code',
          componentType: 'SearchMixCodeFieldForParams',
        },
        {
          property: 'condition',
          name: 'Condition',
          componentType: 'SearchConditionFieldForParams',
        },
        {
          property: 'showArchived',
          name: 'Show Archived Groups',
          label: 'Archived Groups',
          componentType: FilterComponentTypes.Checkbox,
          onChangeHandler: handleShowArchivedChange,
          disabled: isShowArchivedDisabled,
          checkboxIconType: 'visibility',
          color: 'primary',
        },
        {
          property: 'nestedFilter',
          componentType: 'Accordion',
          nestedFilter: {
            property: 'missingInfo',
            name: 'Missing Info',
            componentType: 'ChipsMulti',
            options: [
              {
                name: 'Design Str.',
              },
              {
                name: 'Mix Association',
              },
            ],
            onClickHandler: handleClickChip,
          },
        },
      ],
    },
    {
      name: 'SAMPLES',
      category: 'samples',
      filters: [
        {
          property: 'hasSamples',
          name: 'Has Samples',
          componentType: FilterComponentTypes.Checkbox,
          onChangeHandler: handleHasSamplesChange,
          label: 'Has Samples',
          color: 'primary',
        },
        {
          property: 'samples',
          name: 'Samples',
          componentType: 'RangeSlider',
        },
      ],
    },
  ]

  const addDataButton = (pathname, text) => (
    <Button
      component={Link}
      to={pathname}
      variant="contained"
      color="primary"
      startIcon={<Add />}
      disabled={!tssCanWrite(JWT.roles)}
    >
      {text}
    </Button>
  )

  /** Handles the removal of chips and their respective filters when delete button is clicked */
  const filterChipsHandler = filter => {
    const addDataProperty = filter.property
    setActiveFilters(prev => removeActiveFilter(prev, filter))
    // Set states that need default values back to their defaults
    if (addDataProperty === 'isCO2Design') setConditionInternal('all')
    if (addDataProperty === 'minNumSamples')
      setSliderValues(['', sliderValues[1]])
    if (addDataProperty === 'maxNumSamples')
      setSliderValues([sliderValues[0], ''])
    if (addDataProperty === 'mixCode') setMixCodeInternal('')
    const newSettings = cloneDeep(addDataSettings)
    // Add Data Settings atom only contains arrays, so setting length to 0 resets them all
    newSettings[addDataProperty].length = 0
    // Remove Plants if Customer filter is removed
    if (addDataProperty === 'divisionId') newSettings['plantId'] = []
    if (addDataProperty === 'includeOnlyMissingDesignStrength')
      newSettings['includeOnlyMissingDesignStrength'] = []
    if (addDataProperty === 'includeOnlyMissingMixAssociation')
      newSettings['includeOnlyMissingMixAssociation'] = []
    if (addDataProperty === 'showArchived')
      newSettings['showArchived'] = ['true']
    setAddDataSettings(newSettings)
  }

  /** Clears all Chip Filters and Panel Filters */
  const clearAllFilters = () => {
    const newSettings = cloneDeep(addDataSettings)
    for (const setting in newSettings) {
      if (setting === 'showArchived') {
        newSettings[setting] = ['true']
      } else {
        newSettings[setting] = []
      }
    }
    setConditionInternal('all')
    setMixCodeInternal('')
    setSliderValues(['', ''])
    setAddDataSettings(newSettings)
  }

  const editDataButton = (pathname, state, text) => {
    const to = state ? state : pathname
    return (
      <Button
        component={Link}
        to={to}
        target="_blank"
        variant="contained"
        color="primary"
        startIcon={<Visibility />}
      >
        {text}
      </Button>
    )
  }

  const getValue = filter => {
    if (filter.property === 'showArchived') {
      return addDataSettings.showArchived[0] === 'true'
    }
    if (filter.property === 'hasSamples') {
      return (
        addDataSettings.minNumSamples[0] === '1' &&
        !addDataSettings.maxNumSamples[0]
      )
    }
  }

  const getButtonUrl = id => {
    let currentMixDesign = findMixDesignById(tableData.digestedMixDesigns, id)
    if (currentMixDesign)
      return `${url}/AddMixDesign?divisionId=${addDataSettings.divisionId}&plantId=${currentMixDesign.plantId}&mixDesignId=${id}`
  }

  const getBaleenUrl = id => {
    const baleenUrl = url.replace('/AddData', '/Baleen')
    let currentMixDesign = findMixDesignById(tableData.digestedMixDesigns, id)
    if (currentMixDesign) {
      const variationIds = getBaleenVariationIdsUrl(currentMixDesign)
      return `${baleenUrl}?divisionId=${addDataSettings.divisionId}&plantId=${currentMixDesign.plantId}&mixGroup=${id}&interval=672&${variationIds}&dataColumns=slump&dataColumns=cO2DosageLabel&dataColumns=strengths&isMetric=false`
    }
  }

  //Sets parameters on first render
  useEffect(() => {
    setIsLoading(true)
    if (!isFirstRender) {
      let newUrl = getUrlFromFilters(addDataSettings, 'AddData')
      window.history.replaceState(null, 'New Page Title', newUrl)
    } else {
      // FIRST RENDER
      const params = new URLSearchParams(search)

      const newSettings = getNewSettings(addDataSettings, params)

      // conditionally update settings
      if (!isEqual(newSettings, addDataSettings)) {
        setAddDataSettings(newSettings)
      }
      setIsFirstRender(false)
    }
    setIsLoading(false)
  }, [addDataSettings, setAddDataSettings, isFirstRender, search, setIsLoading])

  //Fill components with data
  useEffect(() => {
    const abortController = new AbortController()
    const { order, orderBy, rowsPerPage, page } = mixDesignTableSettings
    const SortOrder = order === 'asc' ? 'Ascending' : 'Descending'
    let archiveFilter
    if (addDataSettings.showArchived[0] === 'true') {
      archiveFilter = 'IncludeArchived'
    } else {
      archiveFilter = 'OnlyActive'
    }
    const sortAndOrderParams = {
      Limit: rowsPerPage,
      Offset: page * rowsPerPage,
      SortColumn: orderBy,
      SortOrder,
      archiveFilter,
      ...mapSettingsToParams(addDataSettings, 'isCO2Design'),
      ...mapSettingsToParams(addDataSettings, 'minNumSamples'),
      ...mapSettingsToParams(addDataSettings, 'maxNumSamples'),
      ...mapSettingsToParams(addDataSettings, 'plantId'),
      ...mapSettingsToParams(
        addDataSettings,
        'includeOnlyMissingDesignStrength'
      ),
      ...mapSettingsToParams(
        addDataSettings,
        'includeOnlyMissingMixAssociation'
      ),
    }

    //only add mixcode to fetch if not in Association view
    if (addDataSettings.mixCode.length > 0 && !isAssociationView) {
      sortAndOrderParams.mixCode = addDataSettings.mixCode[0]
    }

    const filterByDivision = {
      divisionIds: addDataSettings.divisionId,
    }

    const getMixDesigns = async () => {
      // set the mix designs based on the plant selected

      // dataSummaryFlag is used to allow data fetching when a customer is removed from the filter.
      // This also prevents data from being fetched if a customer has not yet been selected.
      if (!dataSummaryFlag && !currentCustomer.division?.divisionId) return
      try {
        setIsLoading(true)
        const response = await getMixGroupByBatch(
          sortAndOrderParams,
          filterByDivision,
          abortController
        )
        if (response.ok) {
          const {
            count,
            results,
            numCO2Samples,
            numNonCO2Samples,
          } = await response.json()
          const { mixDesignData } = digestMixDesigns(results)
          addTotalCylinders(mixDesignData)
          setMixDesignTableData(prev => {
            return {
              ...prev,
              digestedMixDesigns: mixDesignData,
            }
          })

          if (!currentCustomer.division?.divisionId) {
            setMixDesignMetrics(defaultMetrics)
            setDataSummaryFlag(false)
          } else {
            setMixDesignMetrics({ count, numCO2Samples, numNonCO2Samples })
            setDataSummaryFlag(true)
          }
          setIsLoading(false)
        }
      } catch (error) {
        //aborted fetch requests are fine, we can ignore them since we purposefully abort
        if (!isAbortedFetchError(error)) {
          console.error(error)
        }
      }
    }
    const urlFromSettings = getUrlFromFilters(addDataSettings, '')
    if (
      decodeURIComponent(urlFromSettings) ===
      decodeURIComponent(window.location.search)
    ) {
      getMixDesigns()
      return () => {
        abortController.abort('Another request was made')
      }
    }
  }, [
    isAssociationView,
    setMixDesignTableData,
    currentCustomer.division,
    currentCustomer.plant,
    mixDesignTableSettings,
    addDataSettings,
    search,
    isFirstRender,
    dataSummaryFlag,
  ])

  //Reset to default when customer changes
  useEffect(() => {
    setMixDesignTableSettings(defaultTableSettings)
    setMixDesignTableData(defaultMixTableData)
    setAssociatedMixTableData(defaultMixTableData)
    setConditionInternal('all')
    setMixCodeInternal('')
    setMinSamples('')
    setMaxSamples('')
  }, [currentCustomer.division])

  // init customer options dropdown
  useEffect(() => {
    if (customerOptions.length === 0) {
      setIsLoading(true)
      getDivisionNames(true)
        .then(res => {
          if (res.ok)
            res.json().then(data => {
              setCustomerOptions(data)
              setIsLoading(false)
            })
        })
        .catch(e => console.log(e))
    }
  }, [customerOptions, setCustomerOptions])

  const getAssociatedMixes = async id => {
    const allassociatedMixes = {
      mixDesignId: id,
    }
    try {
      setIsLoading(true)
      const response = await getAssociatedMixesByMixDesignId(allassociatedMixes)
      if (response.ok) {
        const { results } = await response.json()
        const { mixDesignData } = digestMixDesigns(results)
        const currentAssociatedMix = findMixDesignById(mixDesignData, id)
        setAssociatedMixTableData(prev => {
          return {
            ...prev,
            digestedMixDesigns: mixDesignData,
            selectedMixDesigns: [currentAssociatedMix],
            selectedMixVariations: currentAssociatedMix.variations,
            batchActuals: [
              ...mixDesignTableData.batchActuals.filter(
                batchActual => batchActual.mixDesignId === id
              ),
            ],
          }
        })
        setIsAssociationView(true)
        setMixCodeInternal('')
        setConditionInternal('all')
        setMinSamples('')
        setMaxSamples('')
        setIsLoading(false)
      }
    } catch (error) {
      //aborted fetch requests are fine, we can ignore them since we purposefully abort
      if (!isAbortedFetchError(error)) {
        console.error(error)
      }
    }
  }

  //Reset when return to main table
  const handleAssociationView = () => {
    setIsAssociationView(false)
    setAssociatedMixTableData(defaultMixTableData)
    setAssociatedMixTableSettings(defaultTableSettings)
    setMixCodeInternal('')
    setConditionInternal('all')
    setMinSamples('')
    setMaxSamples('')
  }

  useEffect(() => {
    const activeFilters = []
    const filtersToCheck = [
      'divisionId',
      'isCO2Design',
      'mixCode',
      'plantId',
      'minNumSamples',
      'maxNumSamples',
    ]
    let count = 0
    for (const setting in addDataSettings) {
      if (filtersToCheck.includes(setting)) {
        count += addDataSettings[setting].length
        if (addDataSettings[setting].length > 0) {
          activeFilters.push(
            handleChipLabel(
              setting,
              addDataSettings[setting][0],
              currentCustomer
            )
          )
        }
      }
      if (
        setting === 'showArchived' &&
        addDataSettings[setting].includes('false')
      ) {
        count += addDataSettings[setting].length
        if (addDataSettings[setting].length > 0) {
          activeFilters.push(
            handleChipLabel(
              setting,
              addDataSettings[setting][0],
              currentCustomer
            )
          )
        }
      }
      if (
        (setting === 'includeOnlyMissingDesignStrength' ||
          setting === 'includeOnlyMissingMixAssociation') &&
        addDataSettings[setting].includes('true')
      ) {
        count += addDataSettings[setting].length
        activeFilters.push(
          handleChipLabel(setting, addDataSettings[setting][0], currentCustomer)
        )
      }
    }
    setFilterCount(count)
    setActiveFilters(activeFilters)
  }, [isLoading, addDataSettings, currentCustomer])

  useEffect(() => {
    setTableSettings(prevSettings => ({
      ...prevSettings,
      page: 0,
    }))
  }, [
    addDataSettings.divisionId,
    addDataSettings.plantId,
    addDataSettings.mixCode,
    conditionInternal,
    minSamples,
    maxSamples,
    addDataSettings.showArchived,
    setTableSettings,
  ])

  const onClickHandler = () => {
    setOpen(prev => !prev)
  }

  const [expandFilters, setExpandFilters] = useState({
    customer: true,
    mixDetails: false,
    samples: false,
  })

  //Custom hook to open filter panel & expand select sections when no current customer
  useFilterPanelOpen(
    isLoading,
    isFirstRender,
    addDataSettings.divisionId,
    setOpen,
    setExpandFilters
  )

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: '50' }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Container
        maxWidth="xl"
        style={{ padding: '0em 0.5em' }}
        className={classes.mainContainer}
      >
        <Grid item>
          <FilterPanelLogical
            open={open}
            setOpen={setOpen}
            expandFilters={expandFilters}
            setExpandFilters={setExpandFilters}
            filterPanel={filterPanel}
            parentClasses={classes}
            addDataSettings={addDataSettings}
            setAddDataSettings={setAddDataSettings}
            isAssociationView={isAssociationView}
            helperVariables={addDataHelperVariables}
            getValue={getValue}
            filtersClasses={filterClasses}
          />
        </Grid>
        <div
          className={open ? classes.wrapperShifted : classes.wrapperUnshifted}
        >
          <Grid container spacing={4} direction="row">
            <Grid container direction="row" item xs={4}>
              <Grid item xs={12}>
                <Typography variant="h2">Add Data</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  {isAssociationView
                    ? 'Associated Mix Designs'
                    : 'Existing Mix Designs'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" item xs={8}>
              <Grid item xs={12}>
                <AddDataHighLevelSummary mixDesignMetrics={mixDesignMetrics} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={4}>
            <Grid container item spacing={2} direction="row">
              <Grid container item xs={3} style={{ padding: 0 }}>
                <FilterButton
                  open={open}
                  parentClasses={classes}
                  filterCount={filterCount}
                  onClickHandler={onClickHandler}
                />
              </Grid>
              <Grid
                item
                xs={9}
                container
                spacing={2}
                alignItems="center"
                justify="flex-end"
                style={{ paddingRight: 0 }}
              >
                <Grid item className={classes.legend}>
                  <FiberManualRecord
                    style={{ color: baseColors.variations.baseline }}
                  />
                  <Typography>Baseline Variation</Typography>
                </Grid>
                <Grid item className={classes.legend}>
                  <FiberManualRecord
                    style={{ color: baseColors.variations.optimized }}
                  />
                  <Typography>Optimized Variation</Typography>
                </Grid>
                {!isAssociationView && (
                  <Grid item style={{ marginRight: -6 }}>
                    {addDataButton(`${url}/AddMixDesign`, 'Add Mix')}
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={12} style={{ padding: 0 }}>
                <FilterPanelChips
                  activeFilters={activeFilters}
                  chipClickHandler={filterChipsHandler}
                  buttonClickHandler={clearAllFilters}
                />
              </Grid>
              {isAssociationView && (
                <Grid
                  item
                  onClick={handleAssociationView}
                  style={{
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <IconButton>
                    <ArrowBack />
                  </IconButton>
                  <Typography style={{ cursor: 'pointer' }}>
                    Go Back to Table
                  </Typography>
                </Grid>
              )}
              <Grid item xs>
                <Paper variant="outlined">
                  {currentCustomer.division ? (
                    <MixGroupVariationTable
                      isAssociationView={isAssociationView}
                      setTableData={setTableData}
                      tableData={tableData}
                      setTableSettings={setTableSettings}
                      tableSettings={tableSettings}
                      handleAssociationView={handleAssociationView}
                      getButtonUrl={getButtonUrl}
                      getBaleenUrl={getBaleenUrl}
                      getAssociatedMixes={getAssociatedMixes}
                      parentClasses={classes}
                      roles={JWT.roles}
                      mixCodeToFilter={isAssociationView ? mixCodeInternal : ''}
                      conditionToFilter={
                        isAssociationView ? conditionInternal : ''
                      }
                      rowCount={
                        isAssociationView
                          ? associatedMixTableData.digestedMixDesigns.length
                          : mixDesignMetrics.count
                      }
                      tableType={SimpleTableTypes.AddDataMixGroup}
                    />
                  ) : (
                    <>
                      <SimpleTablePresentational
                        headCells={headCellsAddDataView}
                      />
                      <Alert severity="info">
                        Please select a Customer to view Existing Mix Designs
                      </Alert>
                    </>
                  )}
                </Paper>
              </Grid>
              <Grid container item spacing={2} style={{ marginTop: '1em' }}>
                <Grid container item xs={6} justify="flex-start">
                  <Typography variant="h3">Existing Batch Actuals</Typography>
                </Grid>
                <Grid container item xs={6} justify="flex-end">
                  {selectedBatchActuals?.length < 1
                    ? addDataButton(`${url}/AddBatchData`, 'Add Batch Data')
                    : editDataButton(
                        getBatchUrl(
                          selectedBatchActuals,
                          url,
                          addDataSettings.divisionId
                        ),
                        false,
                        getEditOrViewBtnLabel(JWT.roles, 'batchActuals')
                      )}
                </Grid>
                <Grid item xs>
                  <Paper variant="outlined">
                    <AddDataBatchSamplesTableLogical
                      batchSamples={tableData.batchActuals}
                      selected={tableData.selectedBatchActuals}
                      setSelected={setTableData}
                      hidePagination={!currentCustomer.division}
                    />
                    {!currentCustomer.division && (
                      <Alert severity="info">
                        Please select a Customer to view Existing Batch Actuals
                      </Alert>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  )
}

export default AddDataView
