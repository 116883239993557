import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import AlarmsManagementHomeLogical from '../Eng/AlarmsManagement/Views/AlarmsManagementHomeLogical'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  containerOffset: {
    marginTop: '2.6em',
  },
})
interface HardwareHomeProps {
  readonly finalRedirectPath: string
}

function HardwareHome({ finalRedirectPath }: HardwareHomeProps) {
  const classes = useStyles()
  let { path } = useRouteMatch()

  return (
    <Grid container justify="center" className={classes.containerOffset}>
      <Grid item xs={12} sm={11} md={10} xl={9}>
        <Switch>
          <Route
            exact
            path={path}
            render={() => <Redirect to={`${path}/AlarmsManagement`} />}
          />
          <Route path={`${path}/AlarmsManagement`}>
            <AlarmsManagementHomeLogical />
          </Route>
          {/* Catch-all route for any non-existing paths within /Hardware */}
          <Route path={`${path}/*`}>
            <Redirect to={finalRedirectPath} />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  )
}

export default HardwareHome
