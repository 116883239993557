import dataHelpers from '../../../Common/Helpers/DataHelpers'
import {
  ConvertKgPerM3ToLbPerFt3,
  ConvertMMtoINCHUnrounded,
  ConvertMPAToPSIUnrounded,
} from '../../../Common/Helpers/GeneralHelpers'
import {
  CellBehavior,
  ISimpleTableSettings,
  SimpleTableOrder,
  SimpleTableRow,
} from '../../../Common/Logic/Types'
import { baseColors } from '../../../theme/colors'
import typography from '../../../theme/typography'
import {
  getCO2Dosage,
  getCO2DosagePrecision,
} from '../../../TSS/Helpers/BaleenHelpers'
import {
  getCO2DosageUnitLabel,
  getFormattedDate,
} from '../../../TSS/Logic/TSSLogic'
import { translatedCementTypes } from '../../Constants/Constants'
import { IEchoSettings } from '../../Interfaces/EchoInterfaces'
import {
  CementTestSampleResult,
  ICement,
  ICementForDownload,
  ICementTableDownloadRow,
  ICementTestSample,
  IGetCementsConstraints,
  IKelownaCement,
  IKelownaCementTestSample,
} from '../../Logic/Types'

interface FilterParams {
  Country?: string
  State?: string
  City?: string
  Longitude?: number
  Latitude?: number
  Suppliers?: string[]
  [key: string]: string | number | string[] | undefined
}

interface ArrayParams {
  CementTypes?: string[]
  LabResults?: string[]
}

const defaultCementTableSettings: ISimpleTableSettings = {
  page: 0,
  rowsPerPage: 10,
  order: SimpleTableOrder.asc,
  orderBy: 'groupName',
}

const cementTableHeadCells = [
  {
    id: 'dropdownCol',
    width: '30px',
    name: '',
  },
  {
    id: 'state',
    width: '70px',
    name: 'State',
    sortable: true,
  },
  {
    id: 'groupName',
    width: '150px',
    name: 'Group',
    sortable: true,
  },
  {
    id: 'supplier',
    width: '150px',
    name: 'Supplier',
    sortable: true,
  },
  {
    id: 'cementPlantName',
    width: '150px',
    name: 'Plant',
    sortable: true,
  },
  {
    id: 'cementType',
    width: '90px',
    name: 'Cement Type',
    sortable: true,
  },
  {
    id: 'avgCementReduction',
    width: '100px',
    name: 'Avg. Cmt. Red.',
    tooltip: 'Average Cement Reduction',
    sortable: true,
  },
  {
    id: 'avgCO2Dosage',
    width: '100px',
    name: `Avg. CO₂ Dos.`,
    tooltip: 'Average CO₂ Dosage',
    sortable: true,
  },
  {
    id: 'dateTested',
    width: '120px',
    name: `Last Date Tested`,
    sortable: true,
  },
  {
    id: 'labResult',
    width: '120px',
    name: `Lab Result`,
    sortable: false,
  },
]

const cementTableDownloadHeadCellsPart1 = [
  {
    label: 'State',
    key: 'state',
  },
  {
    label: 'Group',
    key: 'groupName',
  },
  {
    label: 'Supplier',
    key: 'supplier',
  },
  {
    label: 'Plant',
    key: 'plant',
  },
  {
    label: 'Cement Type',
    key: 'cementType',
  },
  {
    label: 'Average Cement Adjustment',
    key: 'avgCementAdjustment',
  },
]

const cementTableDownloadHeadCellsPart2 = [
  {
    label: 'Average CO2 Dosage (oz/yd3)',
    key: 'avgCO2Dose',
  },
  {
    label: 'Last Date Tested',
    key: 'lastDateTested',
  },
  {
    label: 'Type Result',
    key: 'result',
  },
  {
    label: 'Cement Adjustment',
    key: 'cementAdjustment',
  },
  {
    label: 'CO2 Dosage (oz/yd3)',
    key: 'cO2Dosage',
  },
  {
    label: 'Design Strength (psi)',
    key: 'designStrength',
  },
]

const cementTableDownloadHeadCellsPart3 = [
  {
    label: 'Cement Loading (lb/yd3)',
    key: 'cementLoading',
  },
  {
    label: 'Slump (in)',
    key: 'slump',
  },
  {
    label: 'Water/Cement',
    key: 'waterCementRatio',
  },
  {
    label: 'SCM Type',
    key: 'scmType',
  },
  {
    label: '%SCM',
    key: 'scmPercent',
  },
  {
    label: 'Prod. Date',
    key: 'productionDate',
  },
  {
    label: 'Test Result',
    key: 'sampleTestResult',
  },
]

// attempting to avoid duplication. not sure why it was flagged
const cementTableDownloadHeadCells = [
  ...cementTableDownloadHeadCellsPart1,
  ...cementTableDownloadHeadCellsPart2,
  ...cementTableDownloadHeadCellsPart3,
]

const resultChipBackgroundColors = {
  [CementTestSampleResult.NotTested]: 'rgba(73, 83, 88, 0.7)',
  [CementTestSampleResult.Negative]: 'rgba(215, 79, 57, 1)',
  [CementTestSampleResult.Positive]: 'rgba(59, 141, 114, 1)',
}

const resultChipOutlineColors = {
  [CementTestSampleResult.NotTested]: 'rgba(73, 83, 88, 0.26)',
  [CementTestSampleResult.Negative]: 'rgba(215, 79, 57, 0.26)',
  [CementTestSampleResult.Positive]: 'rgba(59, 141, 114, 0.26)',
}

/** Returns Cement Plants table data for the Echo interface */
async function getCements(
  constraints: IGetCementsConstraints,
  arrayParams: {},
  abortController: AbortController
) {
  const endpoint = '/Customers/Cements/Table'
  return dataHelpers.fetchDataHelper(
    endpoint,
    constraints,
    arrayParams,
    abortController
  )
}

function getCementTableFilterParams(
  echoSettings: IEchoSettings,
  zipCodeCoordinates: number[] = []
) {
  const params: FilterParams = {}
  //City and ZipCode are mutually exclusive.
  //Sending coordinates to Kelowna for returning plants within 200km radius
  const longLat =
    zipCodeCoordinates.length > 0
      ? zipCodeCoordinates
      : echoSettings.cities?.[0]?.longLat ?? null

  //Single select filters
  const filterSettings = {
    Country: echoSettings.countries[0],
    State: echoSettings.states[0],
    Longitude: longLat?.[0],
    Latitude: longLat?.[1],
    Suppliers: echoSettings.cementSuppliers[0],
  }

  Object.entries(filterSettings).forEach(([key, value]) => {
    if (value) {
      params[key] = value
    }
  })

  //Multi select filters
  const arrayParams: ArrayParams = {
    CementTypes: echoSettings.cementTypes.filter(
      cementType => cementType !== 'Cement' && cementType !== 'Other'
    ),
    LabResults:
      echoSettings.labResults[0] === 'All'
        ? ['Positive', 'Negative', 'NotTested']
        : echoSettings.labResults,
  }

  return { params, arrayParams }
}

const digestCementTestSamples = (
  testSamples: IKelownaCementTestSample[],
  isMetric: boolean
) => {
  return testSamples.map(sample => ({
    batchTestSampleId: sample.batchTestSampleId,
    designStrength: isMetric
      ? sample.designStrengthMpa
      : ConvertMPAToPSIUnrounded(sample.designStrengthMpa),
    cementReduction: sample.cementReductionPercent,
    cO2Dosage: isMetric
      ? sample.cO2DosageLitresPerM3
      : getCO2Dosage(sample.cO2DosageLitresPerM3, 'LitrePerM3', false),
    cementLoading: isMetric
      ? sample.cementLoadingKgPerM3
      : ConvertKgPerM3ToLbPerFt3(sample.cementLoadingKgPerM3),
    slump: isMetric ? sample.slumpMm : ConvertMMtoINCHUnrounded(sample.slumpMm),
    waterCementRatio: sample.waterCementRatio,
    scmTypes: sample.scmTypes,
    scmPercent: sample.scmPercentage,
    productionDate: sample.testDate
      ? getFormattedDate(sample.testDate, 'YYYYMMDD', '-', true)
      : null,
    result: CementTestSampleResult[sample.result],
  }))
}

const getCementTestResult = (digestedSamples: ICementTestSample[]) => {
  let testResult = CementTestSampleResult.NotTested
  let negativeSampleCount = 0
  for (const sample of digestedSamples) {
    if (sample.result === 'Positive') {
      return CementTestSampleResult.Positive
    } else if (sample.result === 'Negative') {
      negativeSampleCount++
    }
  }

  if (
    digestedSamples.length > 0 &&
    negativeSampleCount === digestedSamples.length
  )
    testResult = CementTestSampleResult.Negative

  return testResult
}

const digestCements = (kelownaCements: IKelownaCement[]) => {
  const digestedCements: ICement[] = kelownaCements.map(kelownaCement => {
    const samples = kelownaCement.testSamples
    const country = kelownaCement.country
    const isMetric = country !== 'USA'
    const digestedSamples = digestCementTestSamples(samples, isMetric)

    const testResult = getCementTestResult(digestedSamples)

    return {
      cementId: kelownaCement.cementId,
      state: kelownaCement.state,
      groupName: kelownaCement.groupName,
      supplier: kelownaCement.supplier,
      cementPlantName: kelownaCement.cementPlantName,
      cementType: kelownaCement.cementType
        ? //@ts-ignore
          translatedCementTypes[kelownaCement.cementType]
        : null,
      avgCementReduction: kelownaCement.averageCementReductionPercent,
      avgCO2Dosage: isMetric
        ? kelownaCement.averageCO2DosageLitresPerM3
        : getCO2Dosage(
            kelownaCement.averageCO2DosageLitresPerM3,
            'LitrePerM3',
            isMetric
          ),
      dateTestedTimestamp: kelownaCement.dateTested
        ? getFormattedDate(kelownaCement.dateTested, 'YYYYMMDD', '-', true)
        : null,
      testSamples: digestedSamples,
      result: testResult,
      isMetric,
    }
  })
  return digestedCements
}

// Helper function to convert numeric values with a fixed number of decimals
const convertNumericCell = (
  value: number | null | undefined,
  decimals: number
): string => {
  return value?.toFixed(decimals) ?? '—'
}

// Helper function to convert unit-based values with different decimals for metric and non-metric systems
const convertUnitCell = (
  value: number | null | undefined,
  isMetric: boolean,
  metricDecimals: number,
  nonMetricDecimals: number
): string => {
  return value?.toFixed(isMetric ? metricDecimals : nonMetricDecimals) ?? '—'
}

const generateCementTableCollapsibleRow = (
  sample: ICementTestSample,
  isMetric: boolean
) => {
  return {
    id: sample.batchTestSampleId,
    cells: [
      '',
      convertNumericCell(sample.cementReduction, 2),
      convertNumericCell(
        sample.cO2Dosage,
        getCO2DosagePrecision('LitrePerM3', isMetric)
      ),
      convertUnitCell(sample.designStrength, isMetric, 2, 0),
      convertNumericCell(sample.cementLoading, 0),
      convertUnitCell(sample.slump, isMetric, 0, 1),
      convertNumericCell(sample.waterCementRatio, 3),
      sample.scmTypes ?? 'N/A',
      convertNumericCell(sample.scmPercent, 2),
      sample.productionDate ?? '—',
      {
        behavior: CellBehavior.Chips,
        content: [
          {
            text: sample.result,
            style: {
              backgroundColor: resultChipBackgroundColors[sample.result],
              outline: `2px solid ${resultChipOutlineColors[sample.result]}`,
              fontWeight: typography.fontWeight.bold,
              color: baseColors.text.contrast,
            },
          },
        ],
      },
    ],
  }
}

const getCementReductionLabel = (cement: ICement) => {
  const label =
    cement.avgCementReduction !== null
      ? `${cement.avgCementReduction?.toFixed(2)}%`
      : '—'
  return label
}

const getCO2DosageLabel = (cement: ICement) => {
  const label =
    cement.avgCO2Dosage !== null
      ? `${cement.avgCO2Dosage?.toFixed(
          getCO2DosagePrecision('LitrePerM3', cement.isMetric)
        )} ${getCO2DosageUnitLabel('LitrePerM3', cement.isMetric)}`
      : '—'
  return label
}

const generateCementTableRows = (cements: ICement[]) => {
  //@ts-ignore
  const rows: SimpleTableRow[] = cements.map(cement => {
    const cO2DosageLabel = getCO2DosageLabel(cement)
    const cementReductionLabel = getCementReductionLabel(cement)

    return {
      id: cement.cementId,
      cells: [
        cement.state,
        cement.groupName,
        cement.supplier,
        cement.cementPlantName,
        cement.cementType ?? '—',
        cementReductionLabel,
        cO2DosageLabel,
        cement.dateTestedTimestamp ?? '—',
        {
          behavior: CellBehavior.Chips,
          content: [
            {
              text: cement.result,
              style: {
                backgroundColor: resultChipBackgroundColors[cement.result],
                outline: `2px solid ${resultChipOutlineColors[cement.result]}`,
                fontWeight: typography.fontWeight.bold,
                color: baseColors.text.contrast,
              },
            },
          ],
        },
      ],
      collapsibleRows: cement.testSamples.map(sample =>
        generateCementTableCollapsibleRow(sample, cement.isMetric)
      ),
      isMetric: cement.isMetric,
    }
  })

  return rows
}

const getCementTableData = async (
  echoSettings: IEchoSettings,
  tableSettings: ISimpleTableSettings,
  abortController: AbortController,
  zipCodeCoordinates?: number[]
) => {
  const { params, arrayParams } = getCementTableFilterParams(
    echoSettings,
    zipCodeCoordinates
  )

  const constraints: IGetCementsConstraints = {
    Limit: tableSettings.rowsPerPage,
    Offset: tableSettings.page * tableSettings.rowsPerPage,
    SortColumn: tableSettings.orderBy,
    SortOrder: tableSettings.order === 'asc' ? 'Ascending' : 'Descending',
    ...params,
  }

  const cementsResponse = await getCements(
    constraints,
    arrayParams,
    abortController
  )
  if (cementsResponse.ok) {
    const cementTableData = await cementsResponse.json()
    const digestedCements = digestCements(cementTableData.results)
    const rows = generateCementTableRows(digestedCements)
    const count = cementTableData.count
    return { rows, count }
  } else {
    // The fetch returned an error object.
    const errorStatus = cementsResponse.status
    const errorText = JSON.stringify(cementsResponse)

    // If errorText is an empty object, then no error message was returned.
    const errorMessage =
      errorText && errorText !== '{}'
        ? `Error fetching cement table with status: ${errorStatus} and message: ${errorText}`
        : `Error fetching cement table with status: ${errorStatus}`

    console.error(errorMessage)
    return { rows: [], count: 0 }
  }
}

const updateExpandedRowIds = (
  expandedRowIds: number[],
  clickedRowId: number
) => {
  if (expandedRowIds.includes(clickedRowId)) {
    return expandedRowIds.filter(oldId => oldId !== clickedRowId)
  } else {
    return [...expandedRowIds, clickedRowId]
  }
}

const updateTableSettingsSort = (
  tableSettings: ISimpleTableSettings,
  sortColumn: string
) => {
  const { orderBy, order } = tableSettings
  const isAsc = orderBy === sortColumn && order === 'asc'
  const newOrder = isAsc ? SimpleTableOrder.desc : SimpleTableOrder.asc
  return {
    ...tableSettings,
    order: newOrder,
    orderBy: sortColumn,
  }
}

const updateTableSettingsRowsPerPage = (
  tableSettings: ISimpleTableSettings,
  rowsPerPage: string
) => {
  return {
    ...tableSettings,
    page: 0,
    rowsPerPage: parseInt(rowsPerPage, 10),
  }
}

const updateTableSettingsPage = (
  tableSettings: ISimpleTableSettings,
  newPage: number
) => {
  return {
    ...tableSettings,
    page: newPage,
  }
}

const digestCementsForDownload = (undigestedCements: IKelownaCement[]) => {
  const digestedCements = undigestedCements.map(
    (undigestedCement: IKelownaCement) => {
      const samples = undigestedCement.testSamples
      const digestedSamples = digestCementTestSamples(samples, false)
      const testResult = getCementTestResult(digestedSamples)

      return {
        cementId: undigestedCement.cementId,
        state: undigestedCement.state,
        groupName: undigestedCement.groupName,
        supplier: undigestedCement.supplier,
        cementPlantName: undigestedCement.cementPlantName,
        cementType: undigestedCement.cementType
          ? //@ts-ignore
            translatedCementTypes[undigestedCement.cementType]
          : null,
        avgCementReduction: undigestedCement.averageCementReductionPercent,
        avgCO2Dosage: getCO2Dosage(
          undigestedCement.averageCO2DosageLitresPerM3,
          'LitrePerM3',
          false
        ),
        dateTestedTimestamp: undigestedCement.dateTested
          ? getFormattedDate(undigestedCement.dateTested, 'YYYYMMDD', '-', true)
          : null,
        result: testResult,
        testSamples: digestedSamples,
      }
    }
  )
  return digestedCements
}

export const generateCementRowsForDownload = (
  cements: ICementForDownload[]
) => {
  const downloadableCementRows: ICementTableDownloadRow[] = []
  cements.forEach(cement => {
    const downloadableCement = {
      state: cement.state,
      groupName: cement.groupName,
      supplier: cement.supplier,
      plant: cement.cementPlantName,
      cementType: cement.cementType,
      avgCementAdjustment: cement.avgCementReduction,
      avgCO2Dose: cement.avgCO2Dosage,
      lastDateTested: cement.dateTestedTimestamp,
      result: cement.result,
      cementAdjustment: null,
      cO2Dosage: null,
      designStrength: null,
      cementLoading: null,
      slump: null,
      waterCementRatio: null,
      scmTypes: null,
      scmPercent: null,
      productionDate: null,
      sampleTestResult: null,
    }
    //@ts-ignore (For FUTURE RESOLUTION)
    downloadableCementRows.push(downloadableCement)
    cement.testSamples.forEach(sample => {
      const downloadableTestSample = {
        state: cement.state,
        groupName: cement.groupName,
        supplier: cement.supplier,
        plant: cement.cementPlantName,
        cementType: cement.cementType,
        avgCementAdjustment: cement.avgCementReduction,
        avgCO2Dose: cement.avgCO2Dosage,
        lastDateTested: cement.dateTestedTimestamp,
        result: cement.result,
        cementAdjustment: sample.cementReduction,
        cO2Dosage: sample.cO2Dosage,
        designStrength: sample.designStrength,
        cementLoading: sample.cementLoading,
        slump: sample.slump,
        waterCementRatio: sample.waterCementRatio,
        scmTypes: sample.scmTypes,
        scmPercent: sample.scmPercent,
        productionDate: sample.productionDate,
        sampleTestResult: sample.result,
      }
      //@ts-ignore (For FUTURE RESOLUTION)
      downloadableCementRows.push(downloadableTestSample)
    })
  })
  return downloadableCementRows
}

const cementTableHelper = {
  cementTableHeadCells,
  cementTableDownloadHeadCells,
  defaultCementTableSettings,
  getCementTestResult,
  digestCements,
  digestCementTestSamples,
  getCements,
  getCementTableData,
  getCementTableFilterParams,
  getCementReductionLabel,
  getCO2DosageLabel,
  generateCementTableRows,
  convertNumericCell,
  convertUnitCell,
  generateCementTableCollapsibleRow,
  updateExpandedRowIds,
  updateTableSettingsSort,
  updateTableSettingsRowsPerPage,
  updateTableSettingsPage,
  digestCementsForDownload,
  generateCementRowsForDownload,
}

export default cementTableHelper
