import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import React, { Dispatch, SetStateAction } from 'react'
import {
  PlantDataFilterSectionExpanded,
  ViewDesignsFilterSectionExpanded,
  ViewDesignsHelperVariables,
  PlantDataHelperVariables,
  FilterPanelSection,
  AddDataSettings,
  AddDataHelperVariables,
  MaterialManagerFilterSectionExpanded,
} from '../../../TSS/Logic/Types'
import FilterPanelFilters from './FiltersPanelFilters'
import { Filter, FilterOption } from '../../Logic/Types'

interface IFilterPanelLogical {
  /** Boolean indicating whether or not the filter panel is open & visible */
  open: boolean
  /** Function to change whether or not the filter panel is open */
  setOpen: () => void
  /** An object used to determine which sections in the filter panel are expanded*/
  expandFilters:
    | PlantDataFilterSectionExpanded
    | ViewDesignsFilterSectionExpanded
    | MaterialManagerFilterSectionExpanded
  /** A function that changes which sections in the filter panel are expanded */
  setExpandFilters: Dispatch<
    SetStateAction<
      | PlantDataFilterSectionExpanded
      | ViewDesignsFilterSectionExpanded
      | MaterialManagerFilterSectionExpanded
    >
  >
  /** An array of objects representing all the sections in the filter panel to be rendered */
  filterPanel: Array<FilterPanelSection>
  /** Style classes defined in the parent */
  parentClasses: ClassNameMap<string>
  /** A function which takes a filter as an argument and returns an array of objects, a string, or a boolean. Determines the value of a filter. */
  getValue: (arg1: Filter) => Array<FilterOption> | string | boolean
  /** An object containing helper variables that a filter may need in order to function or pass down to its children */
  helperVariables:
    | ViewDesignsHelperVariables
    | PlantDataHelperVariables
    | AddDataHelperVariables
  filtersClasses: ClassNameMap<string>
  /** Boolean to display the plant selection or not. It is not displayed when adding batch data. Used with CustomerAndLocationSelect */
  showPlants: boolean
  /** Boolean to disable the customer and plant selection when viewing associations. */
  isAssociationView: boolean
  /** Atom for TSS/AddData page settings */
  addDataSettings: AddDataSettings
  /** Function to manipulate Atom for TSS/AddData page settings */
  setAddDataSettings: Dispatch<SetStateAction<AddDataSettings>>
}
const FilterPanelLogical = (props: IFilterPanelLogical) => {
  const {
    open,
    setOpen,
    expandFilters,
    setExpandFilters,
    filterPanel,
    parentClasses,
    getValue,
    helperVariables,
    filtersClasses,
    showPlants,
    isAssociationView,
    addDataSettings,
    setAddDataSettings,
  } = props

  return (
    <div className={parentClasses.filterPanelContainer}>
      <span
        id="panel-sticky"
        className={
          open ? parentClasses.filterPanelOpen : parentClasses.filterPanel
        }
      >
        <FilterPanelFilters
          setOpen={setOpen}
          expandFilters={expandFilters}
          setExpandFilters={setExpandFilters}
          filterPanel={filterPanel}
          getValue={getValue}
          showPlants={showPlants}
          isAssociationView={isAssociationView}
          addDataSettings={addDataSettings}
          setAddDataSettings={setAddDataSettings}
          helperVariables={helperVariables}
          disableAll={!open}
          filtersClasses={filtersClasses}
          parentClasses={parentClasses}
        />
      </span>
    </div>
  )
}

export default FilterPanelLogical
