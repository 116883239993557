import { Grid, MenuItem, Select, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import React from 'react'
import { useHistory } from 'react-router-dom'
import QuicksightDashboard from '../Components/QuicksightDashboard'
import {
  dashboardNameList,
  quicksightDashboardList,
} from '../Constants/UrlMapping'

export default function ProductionDashboards(props) {
  const { currentDashboard, setCurrentDashboard } = props
  const isQuickSightEnabled =
    process.env.REACT_APP_IS_QUICKSIGHT_ENABLED === 'true'
  const history = useHistory()

  const changeCurrentDashboard = event => {
    setCurrentDashboard(event.target.value)
    history.push(`QuickSight/${event.target.value}`)
  }
  return (
    <Grid
      container
      direction="row"
      justifycontent="flex-start"
      alignItems="flex-start"
      spacing={2}
      style={{ marginTop: '1%' }}
    >
      <Grid item style={{ textAlign: 'left', marginLeft: '1%' }}>
        <Typography> Select Dashboard </Typography>
        <Select
          id="dashboard-list"
          value={currentDashboard}
          defaultValue=""
          label="Select Dashboard"
          onChange={changeCurrentDashboard}
        >
          {dashboardNameList.map(dashboard => (
            <MenuItem
              value={dashboard}
              key={quicksightDashboardList[dashboard].dashboardId}
            >
              {quicksightDashboardList[dashboard].title}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        {isQuickSightEnabled ? (
          <QuicksightDashboard
            dashboard={quicksightDashboardList[currentDashboard]}
          />
        ) : (
          <Alert severity="warning">
            <AlertTitle>Warning: Ongoing Maintenance</AlertTitle>
            Dashboards are disabled during planned maintenance window. If
            urgent, please contact #info-devops.
          </Alert>
        )}
      </Grid>
    </Grid>
  )
}
