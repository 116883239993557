import React, { useEffect, useState } from 'react'
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core'
import { atomCurrentCustomer } from '../../Common/atoms'
import { useRecoilValue } from 'recoil'
import PropTypes from 'prop-types'
import { disableSubmitButton, getMaterialName } from '../Logic/TSSLogic'
import { MixGroupConditions } from '../Logic/Types'

const useStyles = makeStyles({
  stickyCard: { position: 'sticky', top: '2em' },
  detailsList: { margin: '0px' },
  comfirmButton: {
    justifyContent: 'flex-end',
    paddingBottom: '1.5em',
    paddingRight: '1em',
  },
})

AddMixSummaryCard.propTypes = {
  /** List all mix details, state object on AddMixDesignView. It populates the card summary. */
  mixDetails: PropTypes.object.isRequired,
  /** Array containing all materials entered in the table, comes from AddMixDesignView. */
  materials: PropTypes.array.isRequired,
  /** Boolean that sets whether the Submit button appears at the bottom. This is set to true within the confirmation dialog component. */
  hideButton: PropTypes.bool,
  /** Boolean flag that indicates whether the component is in edit mode. Optional. */
  isEditMode: PropTypes.bool,
}

/**
 * Component displays mix design details and updates as they are entered in AddMixDesignView.
 * @param {Object} mixDetails - list all mix details, state object on AddMixDesignView. It populates the card summary.
 * @param {Array} materials - array containing all materials entered in the table.
 * @param {Boolean} hideButton - boolean that sets whether the Submit button appears at the bottom. This is set to true within the confirmation dialog component.
 */
function AddMixSummaryCard(props) {
  const classes = useStyles()
  const { mixDetails, materials, hideButton, isEditMode } = props

  const [materialDetails, setMaterialDetails] = useState([])

  // Get current customer.
  const selectedCustomer = useRecoilValue(atomCurrentCustomer)

  // Each key matches the key from prop that we want to display.
  const summaryDetails = {
    condition: `Condition Tag: ${mixDetails.condition}`,
    description: `Description: ${mixDetails.description}`,
    designStrength: `Design Strength: ${mixDetails.designStrength} ${mixDetails.strengthUnit}`,
    strippingStrength: `Stripping Strength: ${mixDetails.strippingStrength} ${mixDetails.strengthUnit}`,
    interval: `Interval: ${mixDetails.interval}`,
    cementType: `Cement Type: ${mixDetails.cementType}`,
    airContentRange: `Air Content Range: ${mixDetails.airContentRange} %`,
    slumpRange: `Slump Range: ${mixDetails.slumpRange} ${mixDetails.slumpUnit}`,
    exposureClass: `Exposure Class: ${mixDetails.exposureClass}`,
    wcRatio: `Water/Cementitious Ratio: ${mixDetails.wcRatio}`,
    baseLineCO2: `Baseline Mix: ${MixGroupConditions.CO2} Dosage: ${
      mixDetails.baseLineCO2
    } ${mixDetails.baseLineCO2 ? '% bwc' : ''}`,
    baseLineCementReduction: `Baseline Mix: Cement Reduction: ${
      mixDetails.baseLineCementReduction
    } ${mixDetails.baseLineCementReduction ? '%' : ''}`,
  }

  useEffect(() => {
    setMaterialDetails(materials)
  }, [materials])

  return (
    <Card className={classes.stickyCard} elevation={1} id="addMixSummaryCard">
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography color="secondary" variant="h3">
              {selectedCustomer?.division?.name} -{' '}
              {selectedCustomer?.plant?.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">Mix Code: {mixDetails.mixCode}</Typography>
            <hr />
            <Typography color="secondary" variant="h5">
              Design Details
            </Typography>
          </Grid>
          <Grid item>
            {mixDetails.associatedMix && (
              <Typography className={classes.detailsList}>
                Associated Mix:
                <br />
                {mixDetails.associatedMix.mixCode} -{' '}
                {mixDetails.associatedMix.baseLineCO2 !== null
                  ? `Baseline ${MixGroupConditions.CO2} Dose: ` +
                    mixDetails.associatedMix.baseLineCO2 +
                    '%'
                  : ''}{' '}
                /{' '}
                {mixDetails.associatedMix.baseLineCementReduction !== null
                  ? 'Baseline Cem Red: ' +
                    mixDetails.associatedMix.baseLineCementReduction +
                    '%'
                  : ''}
              </Typography>
            )}
            {Object.keys(summaryDetails).map(key => {
              /* Only add to card if value has been entered. */
              if (
                mixDetails[key] !== '' &&
                mixDetails[key] !== null &&
                mixDetails[key] !== undefined
              )
                return (
                  <Typography key={key} className={classes.detailsList}>
                    {summaryDetails[key]}
                  </Typography>
                )
              else return null
            })}
          </Grid>
          <Grid item>
            <Typography color="secondary" variant="h5">
              Materials:{' '}
            </Typography>
            {materialDetails.length > 0 &&
              materialDetails.map((mat, idx) => {
                const materialName = getMaterialName(mat)
                return (
                  <Typography key={idx} className={classes.detailsList}>
                    {materialName}:{' '}
                    {mat.quantity ? `${mat.quantity} ${mat.units}` : null}
                  </Typography>
                )
              })}
          </Grid>
        </Grid>
      </CardContent>
      {!hideButton && (
        <CardActions className={classes.comfirmButton}>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            type="submit"
            disabled={disableSubmitButton(
              isEditMode,
              'mixDesign',
              props.search
            )}
          >
            Confirm & Submit
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default AddMixSummaryCard
