import { makeStyles, Typography, Card, CardContent } from '@material-ui/core'
import PropTypes from 'prop-types'
import { atomCurrentCustomer } from '../../Common/atoms'
import { useRecoilValue } from 'recoil'

const useStyles = makeStyles(theme => ({
  cardContent: {
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
  },
  firstCardContent: {
    marginBottom: '-9px',
    marginTop: '-9px',
    marginLeft: '-15px',
  },
  firstFrame: {
    marginTop: theme.spacing(3),
    padding: '8px',
    borderRadius: '8px',
    marginBottom: '8px',
  },
  secondFrame: {
    marginTop: theme.spacing(2),
    padding: '8px',
    borderRadius: '8px',
    marginBottom: '8px',
  },
  label: {
    display: 'inline',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '26px',
    letterSpacing: '0.2px',
    marginRight: theme.spacing(1),
  },
  label1: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '26px',
    letterSpacing: '0.2px',
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },
  value: {
    display: 'inline',
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  mixCodeTag: {
    display: 'inline-block',
    borderRadius: '4px',
    padding: '3px 16px',
    margin: '2px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
  },
}))

AddBatchSummaryCard.propTypes = {
  /** Array containing all materials entered in the table. */
  batchData: PropTypes.array.isRequired,
  /** List of materials to show in summary card. */
  materials: PropTypes.array.isRequired,
}

/**
 * Component displays summary of entered batch data.
 * @param {Array} batchData - array containing all materials entered in the table.
 * @param {Array} materials - list of materials to show in summary card.
 */
function AddBatchSummaryCard(props) {
  const classes = useStyles()
  const { batchData, materials } = props

  // Get current customer.
  const selectedCustomer = useRecoilValue(atomCurrentCustomer)

  // Get unique mix codes.
  const mixCodes = [...new Set(batchData.map(row => row.mixCode))]

  // Get list of batches that have materials added.
  const batchesWithMaterials = batchData.filter(row => {
    const isMatPresent = materials.length > 0
    return isMatPresent
  })

  return (
    <div>
      <Card
        className={classes.firstFrame}
        style={{ width: '504px', height: '94px' }}
        variant="outlined"
      >
        <CardContent className={classes.cardContent}>
          <CardContent className={classes.firstCardContent}>
            <div>
              <Typography className={classes.label}>Producer:</Typography>
              <Typography className={classes.value}>
                {selectedCustomer.division.name}
              </Typography>
            </div>
            <div>
              <Typography className={classes.label}>
                Number of Batches:
              </Typography>
              <Typography className={classes.value}>
                {batchData.length}
              </Typography>
            </div>
            <div>
              <Typography className={classes.label}>
                Batches with Materials:
              </Typography>
              <Typography className={classes.value}>
                {batchesWithMaterials
                  .map(mat => `${mat.ticketId}-${mat.mixCode}`)
                  .join(', ')}
              </Typography>
            </div>
          </CardContent>
        </CardContent>
      </Card>
      <Card
        className={classes.secondFrame}
        style={{ width: '504px', height: '85px' }}
        variant="outlined"
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.contentRow}>
            <Typography className={classes.label1}>
              Mix Codes in Dataset
            </Typography>
            {mixCodes.map(code => (
              <Typography key={code} className={classes.mixCodeTag}>
                {code}
              </Typography>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default AddBatchSummaryCard
