import React from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  InfoOutlined,
  ReportProblemOutlined,
  CheckCircleOutlineOutlined,
  CancelOutlined,
} from '@material-ui/icons'
import { baseColors } from '../../../theme/colors'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { DialogModalSize, DialogModalType } from '../../../TSS/Logic/Types'

export interface IDialogModalProps {
  /** Determines if modal is open. */
  modalOpen: boolean
  /** The type of the modal (Merge, Edit, Success etc.). */
  modalType: DialogModalType
  /** Handles the modal cancel button functionality. */
  handleCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Handles the modal Edit button functionality. */
  handleConfirm?: () => void
  /** Boolean for loading state. */
  isLoading?: boolean
  /** String to be displayed at the dialog header. */
  headerText: string
  /** String to be displayed at the dialog body. */
  contentText: string | JSX.Element
  /** String to be displayed at the cancel button. */
  confirmButtonText?: string
  /** String to be displayed at the confirm button. */
  cancelButtonText?: string
  /** Component to be displayed after the prompt is expanded. */
  cardContent?: React.ReactNode
  /** Style classes defined in the parent. */
  parentClasses: ClassNameMap<string>
  /** Determines if the modal has action buttons. */
  hasAction?: boolean
  /** Determines if action buttons should be disabled. */
  shouldDisable?: boolean
  /** Determines if the modal includes a card component. */
  hasCard?: boolean
  /** The size of the modal. */
  modalSize: DialogModalSize
}

const DialogModal = (props: IDialogModalProps) => {
  const {
    headerText,
    contentText,
    parentClasses,
    modalOpen,
    modalType,
    isLoading,
    handleCancel,
    handleConfirm,
    confirmButtonText,
    cancelButtonText,
    cardContent,
    hasAction,
    hasCard,
    shouldDisable,
    modalSize,
  } = props

  interface HeaderIcons {
    [key: string]: { name: React.ElementType; color: string }
  }

  const headerIcons: HeaderIcons = {
    info: { color: baseColors.info.dark, name: InfoOutlined },
    warning: { color: baseColors.warning.hover, name: ReportProblemOutlined },
    success: {
      color: baseColors.success.hover,
      name: CheckCircleOutlineOutlined,
    },
    error: {
      color: baseColors.error.headerText,
      name: CancelOutlined,
    },
  }

  const getHeaderIcon = () => {
    const Icon = headerIcons[modalType].name
    return (
      <Icon
        fontSize="small"
        style={{ display: 'flex', alignItems: 'center' }}
        className={parentClasses.icon ? parentClasses.icon : classes.icon}
      />
    )
  }

  const useStyles = makeStyles({
    icon: {
      color: headerIcons[modalType].color,
    },
  })

  const classes = useStyles()

  return (
    <Dialog
      open={modalOpen}
      scroll="body"
      fullWidth
      maxWidth={modalSize}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Paper elevation={1}>
        <Grid container>
          <Grid
            container
            item
            justify="space-between"
            className={parentClasses.headerBackground}
          >
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item>{getHeaderIcon()}</Grid>
              <Grid item>
                <Typography variant="h4" className={parentClasses.fontColor}>
                  {headerText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DialogContent className={parentClasses.dialogContent}>
              <Typography
                className={parentClasses.fontColor}
                style={{ marginBottom: hasAction ? '0' : '1em' }}
              >
                {contentText}
              </Typography>
              {hasCard && cardContent}
              {hasAction && (
                <DialogActions>
                  <Grid
                    container
                    justify="flex-end"
                    spacing={2}
                    className={parentClasses.gridContainer}
                  >
                    {cancelButtonText && (
                      <Grid item>
                        <Button
                          data-testid="cancel-button"
                          variant="outlined"
                          className={parentClasses.cancelButton}
                          onClick={handleCancel}
                        >
                          {cancelButtonText}
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        data-testid="confirm-button"
                        variant="contained"
                        className={parentClasses.confirmButton}
                        onClick={handleConfirm}
                        disabled={shouldDisable}
                      >
                        {isLoading ? (
                          <CircularProgress color="inherit" size="2em" />
                        ) : (
                          <>{confirmButtonText}</>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              )}
            </DialogContent>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  )
}

export default DialogModal
