import React from 'react'
import {
  Grid,
  Tooltip,
  Checkbox,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { IMixSelectionProperty } from './CommissionReportMixDesignDetails' // Adjust the import path as necessary
import { baseColors } from '../../theme/colors'

const useStyles = makeStyles(theme => ({
  gridRowContainer: {
    marginTop: '12px',
  },
  checkboxGrid: {
    margin: 'auto',
  },
  propertyNameGrid: {
    margin: 'auto',
  },
  propertyValueGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 'auto',
    textAlign: 'right',
  },
  checkbox: {
    '&.Mui-disabled': {
      color: `${baseColors.action.required} !important`,
    },
  },
  checkboxLabel: {
    marginLeft: '12px',
    display: 'inline-block',
    color: (props: { hasValue: boolean }) =>
      props.hasValue ? theme.palette.text.primary : theme.palette.text.disabled,
  },
}))

export interface ICommissionReportMixDesignDetailsRowProps {
  property: IMixSelectionProperty
  displayProperty: string
  hasProperty: boolean
  hasValue: boolean
  handleCheckboxClick: (
    property: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  getTooltipTitle: (propertyId: string) => string
  getMixDesignDetailsPropertyValue: (property: IMixSelectionProperty) => boolean
}

const CommissionReportMixDesignDetailsRow: React.FC<ICommissionReportMixDesignDetailsRowProps> = ({
  property,
  displayProperty,
  hasProperty,
  hasValue,
  handleCheckboxClick,
  getTooltipTitle,
  getMixDesignDetailsPropertyValue,
}) => {
  const classes = useStyles({ hasValue })

  return (
    <Grid container className={classes.gridRowContainer}>
      <Grid className={classes.checkboxGrid} item xs={1}>
        <Tooltip title={getTooltipTitle(property.id)}>
          <span>
            <Checkbox
              className={classes.checkbox}
              color="primary"
              style={{ padding: 0 }}
              onChange={e => handleCheckboxClick(property.id, e)}
              checked={getMixDesignDetailsPropertyValue(property)}
              disabled={property.isReadOnly || !hasProperty}
            />
          </span>
        </Tooltip>
      </Grid>
      <Grid item xs={7} className={classes.propertyNameGrid}>
        <Typography className={classes.checkboxLabel} variant="body1">
          {property.name}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.propertyValueGrid}>
        <Typography className={classes.checkboxLabel} variant="body1">
          {displayProperty}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CommissionReportMixDesignDetailsRow
