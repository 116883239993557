import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { baseColors } from '../../../theme/colors'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'

export interface IAddDataProgressLostAlertPresentationalProps {
  showAlert: boolean
  closeModal: () => void
  handleLeavePage: () => void
}

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minWidth: '30%',
    maxWidth: '100%',
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: '16px',
    letterSpacing: '0.2px',
    color: baseColors.warning.hover,
    backgroundColor: baseColors.warning.background,
    margin: 0,
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'left',
    marginBottom: theme.spacing(-1),
    marginTop: theme.spacing(-1),
  },
  warningIcon: {
    color: baseColors.warning.hover,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-1),
    fontSize: '2rem',
    width: '28px',
    height: '28px',
  },
  dialogContent: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    color: baseColors.warning.hover,
    fontFamily: 'Urbanist',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '0.6px',
  },
  dialogActions: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  goBackButton: {
    borderColor: baseColors.warning.dark,
    color: baseColors.warning.dark,
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    marginRight: theme.spacing(-0.2),
  },
  leavePageButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: baseColors.warning.dark,
    color: baseColors.secondary.contrast,
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: baseColors.warning.hover,
    },
  },
}))

function AddDataProgressLostAlertPresentational(
  props: IAddDataProgressLostAlertPresentationalProps
) {
  const classes = useStyles()
  const { showAlert, closeModal, handleLeavePage } = props

  return (
    <Dialog
      open={showAlert}
      onClose={closeModal}
      classes={{ paper: classes.dialogPaper }}
      disableBackdropClick
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        <div className={classes.titleContent}>
          <ReportProblemOutlinedIcon className={classes.warningIcon} />
          <Typography variant="h6" className={classes.dialogTitle}>
            Progress Will Be Lost
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>
          By leaving this page, any progress you have made will be lost. <br />
          Do you wish to continue?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={closeModal}
          className={classes.goBackButton}
        >
          No, Go Back
        </Button>
        <Button
          variant="contained"
          onClick={handleLeavePage}
          className={classes.leavePageButton}
        >
          Yes, Leave Page
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddDataProgressLostAlertPresentational
