import React from 'react'
import {
  makeStyles,
  Paper,
  Dialog,
  Typography,
  Grid,
  DialogContent,
  Card,
  CardContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import theme from '../../../theme/muiTheme'
import {
  DialogModalSize,
  DialogModalType,
  IMaterial,
  MaterialExceptionType,
  MaterialObject,
} from '../../Logic/Types'
import {
  convertCO2ToSubscript,
  convertToNameFormat,
  excludeKeys,
  getMaterialModalInfo,
} from '../../Helpers/MaterialManagerHelpers'
import DialogModal from '../../../Common/Components/DialogModal/DialogModal'

const useStyles = makeStyles({
  cardContainer: {
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    flex: 1,
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  typographyContainer: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  textFieldContainer: {
    paddingBottom: '20px',
  },
  // @ts-ignore ts won't acknowledge customClasses being added to theme
  ...theme.customClasses.warningModal,
})

const useStyles2 = makeStyles({
  // @ts-ignore ts won't acknowledge customClasses being added to theme
  ...theme.customClasses.successModal,
})

// Modal styling
const useStyles3 = makeStyles({
  // @ts-ignore ts won't acknowledge customClasses being added to theme
  ...theme.customClasses.errorModal,
  icon: {
    // @ts-ignore
    ...theme.customClasses.errorModal.icon,
    fontSize: '24px',
  },
  headerBackground: {
    // @ts-ignore
    ...theme.customClasses.errorModal.headerBackground,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  gridContainer: {
    // @ts-ignore
    ...theme.customClasses.errorModal.gridContainer,
    paddingRight: '0em',
    paddingBottom: '0em',
  },
  cancelButton: {
    // @ts-ignore
    ...theme.customClasses.errorModal.outlinedErrorButton,
  },
  confirmButton: {
    // @ts-ignore
    ...theme.customClasses.errorModal.containedErrorButton,
  },
  fontColor: {
    ...theme.customClasses.errorModal.fontColor,
    fontWeight: 600,
    margin: 0,
  },
  headerText: {
    ...theme.customClasses.errorModal.headerText,
    fontWeight: 600,
    margin: 0,
  },
})

interface IMaterialManagerModalPresentational {
  /** Determines if modal is open */
  modalOpen: boolean
  /** Controls the opening of the modal */
  setModalOpen: (arg0: boolean) => void
  /** The type of the modal (Similar Material, Duplicate Material, etc.) */
  modalType: MaterialExceptionType | null
  /** States containing material data for comparisons (Similar/Duplicate Modals) */
  existingMaterial: MaterialObject | null
  currentMaterial: MaterialObject | null
  /** Plant names used for displaying Assigned Plants in modals */
  existingMaterialAssignedPlants: string[]
  currentMaterialAssignedPlants: string[]
  /** The material data used for display on Success Modal */
  successData: MaterialObject | null
  /** Handles the modal cancel button functionality */
  handleCancel: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Handles the saving aliases for Similar Material Modal */
  handleSaveAliases: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Handles the adding of aliases for Duplicate Material Modal */
  handleAddAlias: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleEditMaterial: () => void
  /** Handles TextField component data in Similar Material Modal */
  currentTextFieldValue: string
  existingTextFieldValue: string
  /** Handles TextField component data in Similar Material Modal for existing aliases */
  handleExistingTextFieldChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  /** Handles TextField component data in Similar Material Modal for current aliases */
  handleCurrentTextFieldChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  /** The error data for duplicate aliases. Helps with alias field validation on Modals */
  duplicateAliasModalError: string[] | null | undefined
  /** Kelowna generic errors */
  errorMessage: string | undefined
}

function MaterialManagerModalPresentational(
  props: IMaterialManagerModalPresentational
) {
  const {
    modalOpen,
    modalType,
    existingMaterial,
    currentMaterial,
    existingMaterialAssignedPlants,
    currentMaterialAssignedPlants,
    handleCancel,
    handleSaveAliases,
    handleAddAlias,
    handleEditMaterial,
    currentTextFieldValue,
    existingTextFieldValue,
    successData,
    handleExistingTextFieldChange,
    handleCurrentTextFieldChange,
    duplicateAliasModalError,
    errorMessage,
  } = props

  const warningClass = useStyles()
  const successClass = useStyles2()
  const errorClass = useStyles3()

  /** The properties that will be excluded from being displayed in the Similar Material Modal
   * Note: The key names of the Existing Material response object is different than the POST object
   */
  const existingMaterialExclusions = [
    'PrimaryMaterialType',
    'DivisionId',
    'MaterialMappingId',
    'IsIngested',
    'CurrentPlantCompositions',
  ]
  const currentMaterialExclusions = [
    'primaryMaterialType',
    'divisionId',
    'divisionName',
    'overrideSimilarCheck',
    'currentPlantCompositions',
  ]

  const ERROR_MODAL_CANCEL_TEXT = 'Go Back'
  const ERROR_MODAL_CONTENT_TEXT = `An error occurred when trying to perform your request. This can happen where there is an issue connecting to the 
                                    server or an issue with your internet.\nPlease troubleshoot these issues. If you are still unable to submit data, 
                                    send a message to the #info-orca Slack channel and we can help resolve this issue.`

  const duplicateModalHeader = () => {
    return (
      <Grid
        container
        item
        justify="space-between"
        className={warningClass.headerBackground}
      >
        <Grid container direction="row" alignItems="center" spacing={0}>
          <Grid item>
            <ReportProblemOutlinedIcon className={warningClass.icon} />
          </Grid>
          <Grid item>
            <Typography variant="h3" className={warningClass.fontColor}>
              <strong>Duplicate Material</strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      open={modalOpen}
      onClose={handleCancel}
      scroll="body"
      fullWidth
      maxWidth="md"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Paper className={warningClass.modal} elevation={1}>
        {/* Success Modal */}
        {modalType === MaterialExceptionType.SUCCESS && (
          <div data-testid="successModal">
            <Grid
              container
              item
              justify="space-between"
              className={successClass.headerBackground}
            >
              <Grid container direction="row" alignItems="center" spacing={0}>
                <Grid item>
                  <CheckCircleOutlineOutlinedIcon
                    className={successClass.icon}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={successClass.fontColor}>
                    Your{' '}
                    <strong>
                      {convertToNameFormat(successData?.primaryMaterialType)}
                    </strong>{' '}
                    has been saved
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DialogContent>
                <Typography className={successClass.fontColor}>
                  <strong>
                    {convertToNameFormat(successData?.primaryMaterialType)}
                  </strong>{' '}
                  successfully added to the Material Manager
                </Typography>
              </DialogContent>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={1} style={{ margin: '15px' }}>
                <CardContent>
                  {successData?.aliases.length > 0 ? (
                    <Typography className={successClass.fontColor}>
                      {successData?.aliases.map(
                        (string: string, index: number) => (
                          <React.Fragment key={`Success Aliases ${index}`}>
                            {string}
                            {index !== successData.aliases.length - 1 && ', '}
                          </React.Fragment>
                        )
                      )}
                    </Typography>
                  ) : (
                    <Typography className={successClass.fontColor}>
                      No alias
                    </Typography>
                  )}
                  <Typography className={successClass.fontColor}>
                    Subtype: {convertToNameFormat(successData?.materialType)}
                    {successData?.supplierCompany
                      ? ` / Supplier Company: ${successData?.supplierCompany}`
                      : ''}
                    {successData?.supplierPlant
                      ? ` / Plant: ${successData?.supplierPlant}`
                      : ''}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </div>
        )}
        {/* Similar Material Modal */}
        {modalType === MaterialExceptionType.SIMILARMAPPING && (
          <div data-testid="similarModal">
            <Grid
              container
              item
              justify="space-between"
              className={warningClass.headerBackground}
            >
              <Grid container direction="row" alignItems="center" spacing={0}>
                <Grid item>
                  <ReportProblemOutlinedIcon className={warningClass.icon} />
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={warningClass.fontColor}>
                    <strong>Similar Material</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DialogContent>
                {/* At least one of the materials have no aliases */}
                {(existingMaterial?.Aliases.length === 0 ||
                  currentMaterial?.aliases.length === 0) && (
                  <Typography
                    className={warningClass.fontColor}
                    style={{ marginBottom: '20px' }}
                  >
                    To prevent duplicates, please assign a unique alias to
                    {currentMaterial?.aliases.length === 0 &&
                      ' the new material you are adding'}
                    {currentMaterial?.aliases.length === 0 &&
                      existingMaterial?.Aliases.length === 0 &&
                      ' and'}
                    {existingMaterial?.Aliases.length === 0 &&
                      ' the existing material with similar information'}
                    .
                  </Typography>
                )}
                {/* Both materials have aliases */}
                {existingMaterial?.Aliases.length > 0 &&
                  currentMaterial?.aliases.length > 0 && (
                    <Typography
                      className={warningClass.fontColor}
                      style={{ marginBottom: '20px' }}
                    >
                      The material is similar to previously entered materials on
                      Orca. Please review and <strong>confirm</strong> that they
                      are not duplicates.
                    </Typography>
                  )}
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={warningClass.cardContainer}
                >
                  <Grid item xs={6}>
                    <Card elevation={1} className={warningClass.card}>
                      <CardContent className={warningClass.contentContainer}>
                        <div className={warningClass.typographyContainer}>
                          <Typography>
                            <strong>Pre-Existing Material:</strong>
                            <br />
                            {convertCO2ToSubscript(
                              existingMaterial?.PrimaryMaterialType
                            )}
                          </Typography>
                          {Object.entries(existingMaterial as IMaterial).map(
                            ([key, value]) => {
                              if (
                                value !== null &&
                                value.length > 0 &&
                                excludeKeys(key, existingMaterialExclusions)
                              ) {
                                return (
                                  <Typography key={key}>
                                    {getMaterialModalInfo(key, value)}
                                  </Typography>
                                )
                              }
                              return null
                            }
                          )}
                          {existingMaterialAssignedPlants?.length > 0 && (
                            <Typography data-testid="existing-assigned-plants">
                              Assigned Plants:{' '}
                              {existingMaterialAssignedPlants.join(', ')}
                            </Typography>
                          )}
                        </div>
                        <div className={warningClass.textFieldContainer}>
                          {((existingMaterial?.Aliases.length > 0 &&
                            currentMaterial?.aliases.length > 0) ||
                            existingMaterial?.Aliases.length === 0) && (
                            <TextField
                              value={existingTextFieldValue}
                              onChange={handleExistingTextFieldChange}
                              label="Alias"
                              variant="outlined"
                              size="small"
                              name="preExistingAlias"
                              error={duplicateAliasModalError?.includes(
                                existingTextFieldValue.toLowerCase()
                              )}
                              helperText={
                                duplicateAliasModalError?.includes(
                                  existingTextFieldValue.toLowerCase()
                                )
                                  ? 'This alias has already been used. Please choose another.'
                                  : ''
                              }
                              InputProps={{
                                endAdornment: duplicateAliasModalError?.includes(
                                  existingTextFieldValue.toLowerCase()
                                ) && (
                                  <InputAdornment position="end">
                                    <ReportProblemOutlinedIcon color="error" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card elevation={1} className={warningClass.card}>
                      <CardContent className={warningClass.contentContainer}>
                        <div className={warningClass.typographyContainer}>
                          <Typography>
                            <strong>Current Material:</strong>
                            <br />
                            {convertCO2ToSubscript(
                              currentMaterial?.primaryMaterialType
                            )}
                          </Typography>
                          {Object.entries(currentMaterial as IMaterial).map(
                            ([key, value]) => {
                              if (
                                value !== null &&
                                value.length > 0 &&
                                excludeKeys(key, currentMaterialExclusions)
                              ) {
                                return (
                                  <Typography key={key}>
                                    {getMaterialModalInfo(key, value)}
                                  </Typography>
                                )
                              }
                              return null
                            }
                          )}
                          {currentMaterialAssignedPlants?.length > 0 && (
                            <Typography data-testid="current-assigned-plants">
                              Assigned Plants:{' '}
                              {currentMaterialAssignedPlants.join(', ')}
                            </Typography>
                          )}
                        </div>
                        <div className={warningClass.textFieldContainer}>
                          {((existingMaterial?.Aliases.length > 0 &&
                            currentMaterial?.aliases.length > 0) ||
                            currentMaterial?.aliases.length === 0) && (
                            <TextField
                              value={currentTextFieldValue}
                              onChange={handleCurrentTextFieldChange}
                              label="Alias"
                              variant="outlined"
                              size="small"
                              name="currentAlias"
                              error={duplicateAliasModalError?.includes(
                                currentTextFieldValue.toLowerCase()
                              )}
                              helperText={
                                duplicateAliasModalError?.includes(
                                  currentTextFieldValue.toLowerCase()
                                )
                                  ? 'This alias has already been used. Please choose another.'
                                  : ''
                              }
                              InputProps={{
                                endAdornment: duplicateAliasModalError?.includes(
                                  currentTextFieldValue.toLowerCase()
                                ) && (
                                  <InputAdornment position="end">
                                    <ReportProblemOutlinedIcon color="error" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </DialogContent>
            </Grid>
            {/*  */}
            <DialogActions>
              {(existingMaterial?.Aliases.length === 0 ||
                currentMaterial?.aliases.length === 0) && (
                <Grid
                  container
                  justify="flex-end"
                  spacing={2}
                  className={warningClass.gridContainer}
                  style={{ paddingTop: 30 }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      className={warningClass.outlinedWarningButton}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={warningClass.containedWarningButton}
                      onClick={handleSaveAliases}
                      disabled={
                        (currentMaterial?.aliases.length === 0 &&
                          !currentTextFieldValue) ||
                        (existingMaterial?.Aliases.length === 0 &&
                          !existingTextFieldValue)
                      }
                    >
                      Save Alias
                    </Button>
                  </Grid>
                </Grid>
              )}
              {existingMaterial?.Aliases.length > 0 &&
                currentMaterial?.aliases.length > 0 && (
                  <Grid
                    container
                    justify="flex-end"
                    spacing={2}
                    className={warningClass.gridContainer}
                    style={{ paddingTop: 30 }}
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        className={warningClass.outlinedWarningButton}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        className={warningClass.containedWarningButton}
                        onClick={handleSaveAliases}
                        disabled={false}
                      >
                        Confirm
                      </Button>
                    </Grid>
                  </Grid>
                )}
            </DialogActions>
          </div>
        )}
        {/* Duplicate Material Modal */}
        {modalType === MaterialExceptionType.DUPLICATEMAPPING &&
          currentMaterial?.primaryMaterialType === 'Cement' && (
            <div data-testid="duplicateModal-Cement">
              {duplicateModalHeader()}
              <Grid item xs={12}>
                <DialogContent>
                  <Typography className={warningClass.fontColor}>
                    The material you are trying to add already exists. If you
                    would like to add additional alias or cement composition
                    information please edit the existing material.
                  </Typography>
                </DialogContent>
              </Grid>
              <DialogActions>
                <Grid
                  container
                  justify="flex-end"
                  spacing={2}
                  className={warningClass.gridContainer}
                  style={{ paddingTop: 30 }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      className={warningClass.outlinedWarningButton}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-testid="test-edit-existing-material-button"
                      variant="contained"
                      className={warningClass.containedWarningButton}
                      onClick={handleEditMaterial}
                    >
                      Edit Existing Material
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </div>
          )}
        {modalType === MaterialExceptionType.DUPLICATEMAPPING &&
          currentMaterial?.primaryMaterialType !== 'Cement' && (
            <div data-testid="duplicateModal">
              {duplicateModalHeader()}
              <Grid item xs={12}>
                <DialogContent>
                  <Typography className={warningClass.fontColor}>
                    The material you are trying to add already exists. Please
                    add a new alias for the pre-existing material.
                  </Typography>
                  <Card elevation={1}>
                    <CardContent>
                      <Typography>
                        <strong>
                          Existing Alias for Pre-Existing Material:
                        </strong>
                        <br />
                        {existingMaterial?.Aliases.length > 0 ? (
                          <>
                            {existingMaterial?.Aliases.map(
                              (string: string, index: number) => (
                                <React.Fragment
                                  key={`Existing Aliases ${index}`}
                                >
                                  {string}
                                  {index !==
                                    existingMaterial.Aliases.length - 1 && ', '}
                                </React.Fragment>
                              )
                            )}
                          </>
                        ) : (
                          <>No Alias</>
                        )}
                      </Typography>
                      <TextField
                        value={existingTextFieldValue}
                        onChange={handleExistingTextFieldChange}
                        label="Alias"
                        variant="outlined"
                        size="small"
                        name="preExistingAlias"
                        error={duplicateAliasModalError?.includes(
                          existingTextFieldValue.toLowerCase()
                        )}
                        helperText={
                          duplicateAliasModalError?.includes(
                            existingTextFieldValue.toLowerCase()
                          )
                            ? 'This alias has already been used. Please choose another.'
                            : ''
                        }
                        InputProps={{
                          endAdornment: duplicateAliasModalError?.length >
                            0 && (
                            <InputAdornment position="end">
                              <ReportProblemOutlinedIcon color="error" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </CardContent>
                  </Card>
                </DialogContent>
              </Grid>
              <DialogActions>
                <Grid
                  container
                  justify="flex-end"
                  spacing={2}
                  className={warningClass.gridContainer}
                  style={{ paddingTop: 30 }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      className={warningClass.outlinedWarningButton}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={warningClass.containedWarningButton}
                      onClick={handleAddAlias}
                      disabled={!existingTextFieldValue}
                    >
                      Add Alias
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </div>
          )}
        <DialogModal
          modalOpen={modalType === MaterialExceptionType.ERROR}
          headerText={'Invalid Request'}
          contentText={errorMessage || ERROR_MODAL_CONTENT_TEXT}
          modalType={DialogModalType.error}
          parentClasses={errorClass}
          modalSize={DialogModalSize.Small}
          handleConfirm={handleCancel}
          confirmButtonText={ERROR_MODAL_CANCEL_TEXT}
          hasAction={true}
          hasCard={false}
        />
      </Paper>
    </Dialog>
  )
}

export default MaterialManagerModalPresentational
