import React from 'react'
import { Filter } from '../../Logic/Types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'

interface IFilterPanelAccordionProps {
  renderElement: (filter: Filter) => JSX.Element | undefined
  filter: Filter
}

const FilterPanelAccordion = ({
  renderElement,
  filter,
}: IFilterPanelAccordionProps) => {
  return (
    <div>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="filter-accordion"
          data-testid="accordion-filter"
          style={{ paddingLeft: 0, paddingRight: '0.3em', margin: '0' }}
        >
          <Typography>{filter.name}</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ paddingLeft: '0', paddingRight: '0.3em', margin: '0' }}
        >
          {renderElement(filter)}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default FilterPanelAccordion
