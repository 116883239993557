import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import theme from '../../../theme/muiTheme'
import {
  CommissionReportStatus,
  CommissionReportStatusKey,
  DialogModalSize,
  DialogModalType,
  ICommissionReportSettings,
  IMixGroupOptionWithConditions,
  IMixSelection,
  IInsufficientMaturityAgeSamples,
  IInsufficientVariationSamples,
  ICommissionReportSaveReqBody,
} from '../../Logic/Types'
import {
  getConnectorString,
  getDate,
} from '../../../Common/Helpers/GeneralHelpers'
import MixSelection from './MixSelection'
import { atomJWT } from '../../../Common/atoms'
import { tssCanReview, tssCanWrite } from '../../Logic/TSSLogic'
import cloneDeep from 'lodash.clonedeep'
import CommissionReportTestingOverview from './CommissionReportTestingOverview'
import Outliers from './Outliers'
import {
  ICommissionReportCustomer,
  IMixSelectionRow,
} from '../../Views/CommissionReportGeneratorView'
import CommissionReportTestResults from './CommissionReportTestResults'
import EditModeAlert from '../EditModeAlert'
import CommissionReportConclusion from './CommissionReportConclusion'
import InsufficientSamplesBanner from './InsufficientSamplesBanner'
import DialogModal from '../../../Common/Components/DialogModal/DialogModal'
import {
  postCommissionReport,
  putCommissionReport,
} from '../../../Common/Helpers/DataHelpers'
import {
  getReportObjectForSave,
  handleDeleteReport,
} from '../../Helpers/CommissionReportHelpers'
import ErrorDialog from '../../../Common/Components/ErrorDialog'
import { baseColors } from '../../../theme/colors'
import {
  commissionReportSuccessModalStyles,
  deleteReportModalStyles,
} from '../../Constants/CommissionReportConstants'
import { DeleteOutline } from '@material-ui/icons'

export interface ICreateCommissionReportProps {
  readonly companyLogoUrl?: string
  readonly isCommissionReportViewMode: boolean
  readonly isLoadingMixData: boolean
  readonly isValidReportId: boolean
  readonly mixGroupOptions: IMixGroupOptionWithConditions[]
  readonly mixSelectionRows: IMixSelectionRow[]
  readonly mixSelections: IMixSelection[]
  readonly reportId: number | undefined
  readonly reportSettings: ICommissionReportSettings
  readonly selectedCustomer: ICommissionReportCustomer
  readonly selectedTabValue: number
  readonly setCallMixEndpointFlag: Dispatch<SetStateAction<boolean>>
  readonly setIsCommissionReportViewMode: Dispatch<SetStateAction<boolean>>
  readonly setIsLoading: Dispatch<SetStateAction<boolean>>
  readonly setIsPrinting: Dispatch<SetStateAction<boolean>>
  readonly setIsLoadingMixData: Dispatch<SetStateAction<boolean>>
  readonly setMixSelectionRows: Dispatch<SetStateAction<IMixSelectionRow[]>>
  readonly setMixSelections: Dispatch<SetStateAction<IMixSelection[]>>
  readonly setReportSettings: Dispatch<
    SetStateAction<ICommissionReportSettings>
  >
  readonly setSelectedTabValue: Dispatch<SetStateAction<number>>
  readonly variationsWithInsufficientIntervalSamples: IInsufficientMaturityAgeSamples
  readonly variationsWithInsufficientSamplesList: IInsufficientVariationSamples[]
  readonly isReviewErrorModalOpen: boolean
  readonly setIsReviewErrorModalOpen: React.Dispatch<SetStateAction<boolean>>
}

const buttonStyles = {
  height: '40px',
  marginTop: '7px',
}

const useStyles = makeStyles({
  sectionContainer: {
    padding: '24px 32px',
    margin: '24px 12px 24px 12px',
  },
  subSectionContainer: {
    padding: '16px 24px',
    marginTop: '24px',
  },
  summarySectionContainer: {
    padding: '24px 32px',
    margin: '24px 12px 24px 12px',
    maxHeight: '320px',
  },
  tabs: {
    marginBottom: '-1.5em',
    marginLeft: '32px',
    marginTop: '12px',
  },
  tabLabel: {
    fontWeight: 'bold',
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  tabText: {
    '&.Mui-selected': {
      color: baseColors.primary.main,
    },
  },
  indicator: {
    marginLeft: '10px',
  },
  tabIndicator: {
    width: 'fit-content',
  },
  button: buttonStyles,
  deleteButton: {
    ...buttonStyles,
    // @ts-ignore
    ...theme.customClasses.outlinedDeleteButton,
  },
})

const useStyles2 = makeStyles({
  // @ts-ignore
  ...commissionReportSuccessModalStyles,
})

//Delete Modal Styles
const useStyles3 = makeStyles({
  // @ts-ignore
  ...deleteReportModalStyles,
})
function CreateCommissionReport(props: ICreateCommissionReportProps) {
  const {
    companyLogoUrl,
    isCommissionReportViewMode,
    isLoadingMixData,
    isValidReportId,
    mixGroupOptions,
    mixSelectionRows,
    mixSelections,
    reportId,
    setIsPrinting,
    reportSettings,
    selectedCustomer,
    selectedTabValue,
    setCallMixEndpointFlag,
    setIsCommissionReportViewMode,
    setIsLoading,
    setIsLoadingMixData,
    setMixSelectionRows,
    setMixSelections,
    setReportSettings,
    setSelectedTabValue,
    variationsWithInsufficientIntervalSamples,
    variationsWithInsufficientSamplesList,
    isReviewErrorModalOpen,
    setIsReviewErrorModalOpen,
  } = props
  const [isCommissionReport] = useState(true)
  const classes = useStyles()
  const modalClasses = useStyles2()
  const deleteReportModalClasses = useStyles3()
  const JWT = useRecoilValue(atomJWT)
  const producerName = selectedCustomer.divisionName
  const currentUser = `${JWT.firstName} ${JWT.lastName}`
  const author = isValidReportId ? reportSettings.lastUpdatedBy : currentUser
  const date = reportSettings.lastUpdated
    ? reportSettings.lastUpdated
    : getDate(Date.now()) // currentDate for new report
  const mixCode = mixSelections?.[0]?.mixCode ?? ''
  const location = selectedCustomer.plantName
  // State to manage whether the Report Status dropdown should be disabled
  const [isMixSelected, setIsMixSelected] = useState(false)
  // State for tooltip message
  const [tooltipMessage, setTooltipMessage] = useState('')

  const [isEditSaveModalOpen, setIsEditSaveModalOpen] = useState<boolean>(false)
  const [isNewReportSaveModalOpen, setIsNewReportSaveModalOpen] = useState<
    boolean
  >(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [newReportId, setNewReportId] = useState(0)

  const history = useHistory()

  const isOptionDisabled = (statusOption: CommissionReportStatusKey) => {
    switch (statusOption) {
      case 'PendingReview':
        return !tssCanWrite(JWT.roles)
      case 'Reviewed':
        return !tssCanReview(JWT.roles)
      default:
        return false
    }
  }

  const handleMixSelectionChange = (isSelected: boolean) => {
    setIsMixSelected(isSelected)
    setTooltipMessage(
      isSelected && variationsWithInsufficientSamplesList.length === 0
        ? ''
        : 'Unable to change Report Status with errors'
    )
  }

  const handleStatusChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newStatus = event.target.value as CommissionReportStatus
    setReportSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings)
      return {
        ...newSettings,
        reportStatus: newStatus,
      }
    })
  }

  const saveExistingReport = async (
    body: ICommissionReportSaveReqBody,
    isStatusReviewed: boolean
  ) => {
    try {
      const res = await putCommissionReport(reportId, body)
      if (res.ok) {
        setReportSettings(prevSettings => ({
          ...prevSettings,
          lastUpdatedBy: currentUser,
        }))
        setIsLoading(false)
        if (!isStatusReviewed) {
          setIsEditSaveModalOpen(true)
          setTimeout(() => {
            setIsEditSaveModalOpen(false)
            handleViewReport()
          }, 3000)
        } else {
          handleViewReport()
          setIsPrinting(true)
        }
      } else {
        const errorResponse = await res.json()
        throw new Error(errorResponse.error)
      }
    } catch (err) {
      console.error(err)
      setIsErrorModalOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const saveNewReport = async (
    body: ICommissionReportSaveReqBody,
    isStatusReviewed: boolean
  ) => {
    try {
      const res = await postCommissionReport(body)
      if (res.ok) {
        const data = await res.json()
        const newId = data.commissioningReportId
        setReportSettings(prevSettings => ({
          ...prevSettings,
          createdBy: currentUser,
          lastUpdatedBy: currentUser,
          createdByUserEmail: data.createdByUserEmail,
        }))
        setIsLoading(false)
        if (isStatusReviewed) {
          setIsCommissionReportViewMode(true)
          setIsPrinting(true)
          history.push(`/Producers/ReportLibrary/${newId}`)
        } else {
          setNewReportId(newId)
          setIsNewReportSaveModalOpen(true)
        }
      } else {
        const errorResponse = await res.json()
        throw new Error(errorResponse.error)
      }
    } catch (err) {
      console.error(err)
      setIsErrorModalOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSave = async () => {
    setIsLoading(true)
    const isStatusReviewed = reportSettings.reportStatus === 'Reviewed'
    const body = getReportObjectForSave(reportSettings)
    if (isValidReportId) {
      saveExistingReport(body, isStatusReviewed)
    } else {
      saveNewReport(body, isStatusReviewed)
    }
  }

  const handleViewReport = () => {
    setIsCommissionReportViewMode(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleDelete = () => {
    handleDeleteReport(reportId, setIsLoading, setIsDeleteModalOpen)
  }

  const statusOptions = Object.keys(
    CommissionReportStatus
  ) as CommissionReportStatusKey[]

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        spacing={3}
      >
        {isValidReportId && (
          <EditModeAlert
            roles={JWT.roles}
            isCommissionReportViewMode={isCommissionReportViewMode}
            setIsCommissionReportViewMode={setIsCommissionReportViewMode}
            isCommissionReport={isCommissionReport}
            producerName={producerName}
            mixCode={mixCode}
            location={location}
          />
        )}
      </Grid>
      <Typography
        variant="body2"
        style={{ margin: '8px 0px', marginTop: '8px' }}
      >
        <span style={{ fontWeight: 'bold' }}>
          {isValidReportId ? 'Last Updated By: ' : 'Created By: '}
        </span>
        {`${author} on ${date}`}
      </Typography>
      <Grid container justifyContent="space-between">
        <Typography variant="h2" style={{ marginBottom: '8px' }}>
          {`CarbonCure Commissioning Report - ${producerName}`}
        </Typography>
        {companyLogoUrl && (
          <img
            src={companyLogoUrl}
            alt="company logo"
            style={{ width: '100px', height: '30px', objectFit: 'contain' }}
          />
        )}
      </Grid>
      <Grid container spacing={3}>
        {variationsWithInsufficientSamplesList.length > 0 && (
          <InsufficientSamplesBanner
            variationsWithInsufficientSamples={
              variationsWithInsufficientSamplesList
            }
            bannerType="default"
            reportSettings={reportSettings}
          />
        )}
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        spacing={3}
      >
        <Paper elevation={1} className={classes.sectionContainer}>
          <Typography variant="h3" style={{ marginBottom: '12px' }}>
            Mix Selection
          </Typography>
          <MixSelection
            onMixSelectionChange={handleMixSelectionChange}
            reportSettings={reportSettings}
            mixSelections={mixSelections}
            mixSelectionRows={mixSelectionRows}
            setMixSelectionRows={setMixSelectionRows}
            mixGroupOptions={mixGroupOptions}
            setCallMixEndpointFlag={setCallMixEndpointFlag}
            setReportSettings={setReportSettings}
            setMixSelections={setMixSelections}
            setIsLoadingMixData={setIsLoadingMixData}
            variationsWithInsufficientSamplesList={
              variationsWithInsufficientSamplesList
            }
          />
        </Paper>
        <Paper elevation={1} className={classes.sectionContainer}>
          {mixSelections.length > 0 && (
            <CommissionReportTestingOverview
              reportSettings={reportSettings}
              setReportSettings={setReportSettings}
              mixSelections={mixSelections}
              isLoadingMixData={isLoadingMixData}
              selectedCustomer={selectedCustomer}
              isValidReportId={isValidReportId}
            />
          )}
        </Paper>
      </Grid>
      <Grid container spacing={3}>
        {Object.keys(variationsWithInsufficientIntervalSamples).length > 0 && (
          <InsufficientSamplesBanner
            variationsWithInsufficientIntervalSamples={
              variationsWithInsufficientIntervalSamples
            }
            bannerType="interval"
            reportSettings={reportSettings}
          />
        )}
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        spacing={3}
        style={{ width: '100%' }}
      >
        {mixSelections.length > 1 && (
          <Tabs
            data-testid="test-result-tabs"
            value={selectedTabValue}
            onChange={(_, newValue) => setSelectedTabValue(newValue)}
            indicatorColor="primary"
            className={classes.tabs}
            classes={{
              indicator: classes.indicator,
            }}
            TabIndicatorProps={{
              className: classes.tabIndicator,
            }}
          >
            {mixSelections?.map((mixSelection, index) => {
              return (
                <Tab
                  key={mixSelection.mixDesignId}
                  label={
                    <Typography variant="h5" className={classes.tabLabel}>
                      {`Mix Code ${mixSelection?.mixCode}`}
                    </Typography>
                  }
                  id={`tab${index}`}
                  className={classes.tabText}
                />
              )
            })}
          </Tabs>
        )}
        <Paper elevation={1} className={classes.sectionContainer}>
          <Typography variant="h3" style={{ marginBottom: '24px' }}>
            {`Test Results ${mixSelections[selectedTabValue]?.mixCode}`}
          </Typography>
          <CommissionReportTestResults
            mixSelections={mixSelections}
            reportSettings={reportSettings}
            setReportSettings={setReportSettings}
            tabValue={selectedTabValue}
            parentClasses={classes}
            isCommissionReportViewMode={isCommissionReportViewMode}
            isPrinting={false}
          />{' '}
          <Paper elevation={1} className={classes.subSectionContainer}>
            <Outliers
              reportSettings={reportSettings}
              setReportSettings={setReportSettings}
              mixSelections={mixSelections}
              tabValue={selectedTabValue}
            />{' '}
          </Paper>
        </Paper>
        <Paper elevation={1} className={classes.summarySectionContainer}>
          <CommissionReportConclusion
            reportSettings={reportSettings}
            setReportSettings={setReportSettings}
            isLoadingMixData={isLoadingMixData}
            selectedCustomer={selectedCustomer}
            isValidReportId={isValidReportId}
          />
        </Paper>
        <Grid
          container
          justifyContent="space-between"
          style={{ margin: '24px 10px' }}
        >
          <Grid item>
            {reportId && (
              <Button
                variant="outlined"
                size="medium"
                className={classes.deleteButton}
                startIcon={<DeleteOutline />}
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete Report
              </Button>
            )}
          </Grid>
          <Grid item style={{ display: 'flex', gap: '24px' }}>
            <Tooltip title={tooltipMessage} placement="bottom-start" arrow>
              <span>
                <TextField
                  data-testid="report-status-dropdown"
                  select
                  size="small"
                  variant="outlined"
                  label={'Report Status'}
                  style={{ width: '200px', height: '40px' }}
                  disabled={
                    !isMixSelected ||
                    variationsWithInsufficientSamplesList.length > 0
                  }
                  value={reportSettings?.reportStatus}
                  onChange={handleStatusChange}
                >
                  {statusOptions.map(statusOption => (
                    <MenuItem
                      key={statusOption}
                      value={statusOption}
                      disabled={isOptionDisabled(statusOption)}
                    >
                      {CommissionReportStatus[statusOption]}
                    </MenuItem>
                  ))}
                </TextField>
              </span>
            </Tooltip>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={classes.button}
              onClick={handleSave}
            >
              Save Report
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DialogModal
        modalOpen={isEditSaveModalOpen}
        modalType={DialogModalType.success}
        headerText={'Progress Saved'}
        contentText={
          <Typography variant="body2">
            Your progress has successfully saved, now entering view mode for
            commissioning report.
          </Typography>
        }
        parentClasses={modalClasses}
        modalSize={DialogModalSize.Small}
        hasAction={false}
        hasCard={false}
      />
      <DialogModal
        modalOpen={isNewReportSaveModalOpen}
        modalType={DialogModalType.success}
        headerText={'Report Saved'}
        contentText={
          <Typography variant="body2">
            <strong>{selectedCustomer.divisionName}</strong> report for{' '}
            {mixSelections.map((mix, index) => (
              <React.Fragment key={mix.mixCode}>
                <strong>{mix.mixCode}</strong>
                {getConnectorString(mixSelections.length, index)}
              </React.Fragment>
            ))}{' '}
            successfully saved.
          </Typography>
        }
        parentClasses={modalClasses}
        modalSize={DialogModalSize.Small}
        handleCancel={() => {
          handleViewReport()
          setIsNewReportSaveModalOpen(false)
          history.push(`/Producers/ReportLibrary/${newReportId}`)
        }}
        handleConfirm={() => history.push('/Producers/ReportLibrary')}
        cancelButtonText={'View Report'}
        confirmButtonText={'Go Back To Report Library'}
        hasAction={true}
        hasCard={false}
      />
      {isErrorModalOpen && (
        <ErrorDialog
          headerText={'Invalid Request'}
          setErrorMessage={() => setIsErrorModalOpen(false)}
          errorMessage={
            <>
              <Typography variant="body2">
                An error occurred when trying to perform your request. This can
                happen when there is an issue connecting to the server, an issue
                with your internet, or your request was not correctly formatted.
              </Typography>
              <Typography variant="body2" style={{ marginTop: '1em' }}>
                First, please confirm your internet connection. If the issue
                persists, reach out to the <u>#info-orca</u> Slack channel for
                assistance and, if available, include the error code below.
              </Typography>
            </>
          }
        />
      )}
      {isReviewErrorModalOpen && (
        <ErrorDialog
          headerText={'Saving Error'}
          setErrorMessage={() => setIsReviewErrorModalOpen(false)}
          errorMessage={
            <>
              <Typography variant="body2">
                An error occurred when trying to save the reviewed report. This
                can happen when there is an issue connecting to the server or an
                issue with your internet.
              </Typography>
              <Typography variant="body2" style={{ marginTop: '1em' }}>
                Please try to troubleshoot these issues and retry saving the
                reviewed report. If you are still unable to save the report,
                send a message to the #info-orca Slack channel and we can help
                resolve this issue.
              </Typography>
            </>
          }
        />
      )}
      <DialogModal
        modalOpen={isDeleteModalOpen}
        modalType={DialogModalType.warning}
        headerText={'Permanently Delete Report'}
        contentText={
          <Typography variant="body2">
            This action is <strong>irreversible</strong>. Deleting this report
            will result in permanent loss of all progress with it. Are you sure
            you want to delete this report?
          </Typography>
        }
        parentClasses={deleteReportModalClasses}
        modalSize={DialogModalSize.Small}
        hasCard={false}
        hasAction={true}
        handleCancel={() => setIsDeleteModalOpen(false)}
        cancelButtonText="No, Cancel"
        confirmButtonText="Yes, Delete Report"
        handleConfirm={handleDelete}
      />
    </div>
  )
}

export default CreateCommissionReport
