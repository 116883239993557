import React from 'react'
import {
  makeStyles,
  Paper,
  Dialog,
  Typography,
  Grid,
  Button,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import theme from '../../../theme/muiTheme'

function CommissioningDialogPresentational(props) {
  CommissioningDialogPresentational.propTypes = {
    /** Boolean to determine whether the modal is open */
    open: PropTypes.bool.isRequired,
    /** Function to close the modal */
    handleClose: PropTypes.func.isRequired,
    /** The content to display in the modal */
    modalContent: PropTypes.object.isRequired,
    /** Upate the selected division to Commissioning Complete status */
    updateCommissioningStatus: PropTypes.func.isRequired,
  }
  const { open, handleClose, modalContent, updateCommissioningStatus } = props

  const useStyles = makeStyles({
    ...theme.customClasses.warningModal,
  })

  const classes = useStyles()

  return (
    <Dialog open={open} onClose={handleClose}>
      <Paper className={classes.modal} elevation={1} spacing={2}>
        <Grid container direction="column" spacing={0}>
          <Grid
            container
            item
            justify="space-between"
            className={classes.headerBackground}
          >
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item>
                <ReportProblemOutlinedIcon className={classes.icon} />
              </Grid>
              <Grid item>
                <Typography variant="h3" className={classes.fontColor}>
                  <strong>Permanently Change:</strong> Commissioning State
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item className={classes.gridContainer}>
            <Typography className={classes.fontColor}>
              Confirming this will <strong>permanently</strong> change{' '}
              <strong>{modalContent.customer} </strong>
              from Commissioning Phase to Commissioning Complete.
              <br />
              Do you wish to continue?
            </Typography>
          </Grid>
          <Grid
            container
            item
            justify="flex-end"
            spacing={2}
            className={classes.gridContainer}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="warning"
                className={classes.outlinedWarningButton}
                onClick={handleClose}
              >
                No, Go Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="warning"
                className={classes.containedWarningButton}
                onClick={updateCommissioningStatus}
              >
                Yes, Confirm Changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  )
}

export default CommissioningDialogPresentational
