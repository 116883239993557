import { ChangeEvent, SetStateAction } from 'react'
import { IMixGroupVariationTableSettings } from '../Components/MixGroupVariationTable/MixGroupVariationTable'
import { CommissioningFilterOptions } from '../Constants/CommonConstants'
import { Filter } from '../../Common/Logic/Types'
import { RouteComponentProps } from 'react-router-dom'

export type BatchInterval =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 28
  | 56

export type MixMaterial =
  | 'Cement'
  | 'CementTypeI'
  | 'CementTypeIS'
  | 'CementTypeIP'
  | 'CementTypeIL'
  | 'CementTypeI_II'
  | 'CementTypeI_IIMH'
  | 'CementTypeI_II_LA'
  | 'CementTypeIII'
  | 'CementTypeIV'
  | 'CementTypeII_V'
  | 'CementTypeN'
  | 'CementTypeS'
  | 'CementBlendedHydraulic'
  | 'CoarseAggregate'
  | 'IntermediateAggregate'
  | 'FineAggregate'
  | 'Fiber'
  | 'Water'
  | 'CO2'
  | 'SCM'
  | 'Slag'
  | 'FlyAsh'
  | 'FlyAshC'
  | 'FlyAshF'
  | 'FlyAshN'
  | 'SilicaFume'
  | 'ReclaimedWater'
  | 'Admixture'
  | 'AirEntrainer'
  | 'Retarder'
  | 'SuperPlasticizer'
  | 'WaterReducer'
  | 'HighRangeWaterReducer'
  | 'MidRangeWaterReducer'
  | 'LowRangeWaterReducer'
  | 'ViscosityModifier'
  | 'HydrationStabilizer'
  | 'CorrosionInhibitor'
  | 'ShrinkageReducer'
  | 'Accelerator'
  | 'NonChlorideAccelerator'
  | 'ChlorideAccelerator'

export interface StrengthReading {
  batchStrength: number
  batchInterval: BatchInterval
}

export interface MixDesignFromBatchByMix {
  mixDesignId: number
  divisionName: string
  plantName: string
  locationAddress: string
  plantId: number
  mixCode: string
  timestamp: string
  cementPlantId: number
  preCCMixCode: string
  isCO2Design: boolean
  cementSupplier: string
  waterCementRatio?: number
  isRequiredStrengthRisky: boolean
  strengthIntervalDays: number | null
  testSamples: Array<TestSample>
  cementitioutMaterials: Array<CementitiousContent>
  scmContentTotal: number
  totalStrengthReadings: number
  totalStrengthReadings28Days: number
  totalSamples28Days: number
  averageStrengthMpa: number
  strengthReadingStd28Days: number
  designStrengthMpa: number | null
  requiredStrengthMpa: number
  overDesign: number
  cementitiousContentKgPerM3: number
  cementitiousEfficiency: number
  scmPercent: number
  yieldUnitless: number | null
  isInCommissioning: boolean
  mixVariations: Array<MixGroupVariation>
  cO2Dosage: number | null
  cO2DosageUnit: CO2DosageUnit
  cementReductionPercent: number | null
  isActiveMixGroup: boolean
}

export type CO2DosageUnit =
  | 'LitrePerM3'
  | 'PercentOfCement'
  | 'Litre'
  | 'mLitrePerM3'
  | 'OuncePerY3'
  | null

/**
 * The batch actuals response from Kelowna all units are in metric
 */
export interface BatchTestSampleAndStrengthByMixId {
  batchSampleId: number
  plantName: string | null
  batchCementPlant: string | null
  batchCementReduction: number | null
  externalTicketId: string
  airContent: number | null
  slumpMm: number | null
  concreteTemperature: number | null
  ambientTemperature: number | null
  batchWaterCementRatio: number | null
  cO2Dosage: number | null
  cO2DosageUnit: CO2DosageUnit
  loadSizeM3: number | null
  densityKgPerM3: number | null
  weatherCondition: string | null
  truckNumber: string
  wasCuredInField: boolean | null
  wasTestedInField: boolean | null
  notes: string | null
  batchIngredients: {
    materialType: MixMaterial
    materialName: string
    materialQuantity: number
    materialSource: string
    materialUnit: 'Kg' | 'Litre' | 'Package'
  }
  cementContent: number
  productionDate: string
  strengthReadings: [
    {
      batchStrength: number
      batchInterval: number
    }
  ]
  averageStrength7Days: number | null
  averageStrength28Days: number | null
}

export interface CementitiousContent {
  cementitiousContent: number
  cementitiousContentUnit: string
}

export interface TestSample {
  testCategory: string | null
  batchSampleId: number
  airContent: number | null
  slump: number | null
  concreteTemperature: number | null
  ambientTemperature: number | null
  batchWaterCementRatio: number | null
  cO2Dosage: number
  cO2DosageUnit: CO2DosageUnit
  productionDate: string
  strengthReadings: Array<StrengthReading>
  sampleAveragesByInterval: Array<StrengthReading>
  cementContent: number
  notes: string | null
  externalTicketId: string
}

/**
 * Currently supported Units: Metric, Imperial, and LatinAmerican (which is metric, but with kg/(cm^2) for strengths)
 */
export enum Units {
  Metric,
  Imperial,
  LatinAmerican,
}

/**
 * A "Fresh Property Object" is just used to derive
 * a type from the fresh properties as defined in digestMixDesigns
 *
 * Should eventually either stop being used, or replaced with some other function to get
 * the resultant
 */
export interface FreshPropertyObject {
  batchId: number
  air: number | null
  slump: number | null
  concreteTemperature: number | null
  ambientTemperature: number | null
  batchWaterCementRatio: number | null
  batchCementContent: number | null
  co2Dosage: number | null
  co2DosageLabel?: number | null
  co2DosageUnit: CO2DosageUnit
  co2DosageUnitLabel?: string
  batchStrength: number | null
  batchInterval: BatchInterval | null
  productionDate: string
  batchNotes: string | null
  externalTicketId: string
  testCategory: string | null
  isMetric: boolean
}

export type FreshPropertyNumberKeys =
  | 'air'
  | 'slump'
  | 'concreteTemperature'
  | 'ambientTemperature'
  | 'batchWaterCementRatio'
  | 'batchCementContent'
  | 'co2Dosage'
  | 'batchStrength'
  | 'batchInterval'
  | 'productionDate'
  | 'testCategory'
  | 'co2DosageLabel'

export interface AlertObject {
  name: string
  mixDesignId: number
  mixCode: string
  producer: string
  description: string
}

/**
 * Digested Mix Design as is currently represented on TSSLogic
 *
 * Will eventually be replaced with MixDesignWithBatchDataClass in all instances
 *
 * Represents the return value from digestMixDesign() in TSSLogic
 */
export interface DigestedMixDesign {
  divisionId: number
  plantId: number
  mixDesignId: number
  producer: string
  mixCode: string
  locationName: string
  locationAddress: string
  preCCMixCode: string
  totalCylinders: number
  totalBatches: number
  designStrength: number | null
  requiredStrength: number | null
  averageStrength: number | null
  standardDeviation: number | null
  overDesign: string
  interval: string
  condition: MixGroupConditions
  waterCementRatio: number | undefined
  cementSupplier: string
  totalSamples28Days: number
  freshProperties: Array<FreshPropertyObject>
  cementitiousEfficiency: number | null
  cementAmountTotal: number | null
  percentScm: number
  totalYield: number | null
  productionDates: string[]
  inCommissioning: boolean
  cO2Dosage: number | null
  cO2DosageUnit: CO2DosageUnit
  cementReductionPercent: number | null
  variations: Array<MixGroupVariation>
  status: MixGroupStatus
  isMetric?: boolean
}

export interface DigestedBatchActual {
  mixDesignId: number
  ticketId: string
  batchTestSampleId: number
  producer: string
  mixCode: string
  variationId: string
  totalCylinders: number
  productionDate: string
  slump: number | null
  air: number | null
  concreteTemperature: number | null
  cementContent: number | null
  strengthAvg7Days: number | null
  strengthAvg28Days: number | null
  densityKgPerM3: number | null
  notes: string | null
}

export type TestCategoriesKey =
  | 'FieldData'
  | 'HistoricalData'
  | 'ThreePointCurves'
  | 'Regulatory'
  | 'CommissioningTrial'
  | 'CarbonCureResearch'
  | 'ExternalLab'
  // For handling legacy test category data
  | 'Other'
  | 'IssueTroubleshooting'
  | 'DosageRamp'
  | 'ThreePercentCutTesting'
  | 'FivePercentCutTesting'
  | 'CementReduction'
  | 'CommercialMixDesignData'
  | 'DotDurabilityStudy'

export enum TestCategories {
  FieldData = 'Field Data',
  HistoricalData = 'Historical',
  ThreePointCurves = 'Three Points Curve',
  DotDurabilityStudy = 'Regulatory',
  Regulatory = 'Regulatory',
  CommissioningTrial = 'Commissioning Trial',
  CarbonCureResearch = 'CarbonCure Lab',
  ExternalLab = 'NonCarbonCure Lab',
  // For handling legacy test category data
  Other = 'Other',
  IssueTroubleshooting = 'Issue Troubleshooting',
  DosageRamp = 'Dosage Ramp',
  ThreePercentCutTesting = '3% Cut Testing',
  FivePercentCutTesting = '5% Cut Testing',
  CementReduction = 'Cement Reduction',
  CommercialMixDesignData = 'Commericial Mix Design Data',
}

export interface MixGroupVariation {
  cementReductionPercent: number | null
  averageStrength: number | null
  cO2Dosage: number | null
  cO2DosageUnit: CO2DosageUnit
  variationType: VariationTypes | null
  sampleCount: number
  cylinderCount: number
  averageStrengthMpa: number
  overDesign: number
  cementitiousEfficiency: number | null
  freshProperties: Array<FreshPropertyObject>
  uniqueProductionDates?: Set<string>
  variationId: string
  variationIdLabel: string
  mixCode: string
  cells?: Array<string | number | null | undefined>
  interval: string
  designStrength: number | null
  condition: MixGroupConditions
  requiredStrength: number | null
  productionDates: Array<string>
  locationName: string
  status: MixGroupStatus
  mixDesignId: number
  producer: string
  scmPercent: number
  cementAmountTotal: number | null
  waterCementRatio?: number | null
  totalYield: number | null
  requiredStrengthMpa: number | null
  isMetric: boolean
  cO2DosageLabel: number | null
  cO2DosageUnitLabel: string
}

export interface MixVariationBatch {
  batchSampleId: number
  airContent: number
  slumpMm: number
  concreteTemperature: number
  ambientTemperature: number
  batchWaterCementRatio: number
  productionDate: string
  cO2Dosage: number | null
  cO2DosageUnit: CO2DosageUnit
  cementReduction: number
  strengthReadings: Array<{
    batchStrength: number
    batchInterval: BatchInterval
  }>
  cementContent: number
  notes: string
  externalTicketId: string
  testCategory: string
  averageStrength7Days: number | null
  averageStrength28Days: number | null
  densityKgPerM3: number | null
}

export interface MixVariationBatchList extends Array<MixVariationBatch> {}

export enum VariationTypes {
  BASELINE = 'Baseline',
  OPTIMIZED = 'Optimized',
  OPTIMIZEDALT = 'CarbonCure',
}

export enum MixGroupConditions {
  CO2 = 'CO₂',
  NOCO2 = 'No CO₂',
}

export enum MixGroupStatus {
  ACTIVE = 'Active',
  ARCHIVED = 'Archived',
}

type VariationId = string

export interface AddDataTableVariation {
  variationId: VariationId
  variationType: string
  cells: Array<string | number | null>
  mixCode: string
}

export interface PlantOptions {
  activeMasterUnit: number | null
  address: string
  averageLoadSizeM3: number | null
  batchesPerTicket: number
  city: string
  commercialPercent: number | null
  confirmedSourcesTimestamp: string
  country: string
  creationTimestamp: string
  displayName: string
  divisionId: number
  dotPercent: number | null
  eGridRegion: string | null
  eGridRegionId: number | null
  externalPlantId: number | null
  isCementSupplyConsistent: boolean | null
  lastLynxFileSeenTimestamp: string | null
  latitude: number
  longitude: number
  lynxInstalledTimestamp: string | null
  lynxStartTimestamp: string | null
  maxBatchSizeM3: number | null
  monthlyFee: number
  name: string
  plantId: number
  plantType: PlantType
  residentialPercent: number | null
  salesperson: string
  sfAccountId: string
  state: string
  tankRentalFee: number | null
  timezone: string
  tssNote: string | null
}

export interface CustomerOptions {
  [x: string]: any
  corporationId: number
  divisionId: number
  isImperial: boolean
  name: string
}

export interface CustomerDivision {
  divisionId: number | null
  divisionName: string | null
}

export interface CementPlantOptions {
  // synonymous with supplier options
  name: string
  cementPlantId: number
}

export interface PlantComposition {
  plantName: string
  plantId: number | string
  cementCompositions: Array<CementComposition>
}

export interface CementComposition {
  effectiveDate: string
  cementComponents: Array<CementComponent>
}
export interface CementComponent {
  materialId: number | string | null
  aliases?: Array<string> | null
  cementName: string | null
  cementType: string
  cementPlantName: string
  cementPercentage: number
  pendingCement?: boolean // Kelowna does not take this property. This is to help determine if a new cement has been added to a composition in Add Material View
}

export interface CurrentPlantCompositions {
  effectiveDate: string
  cementComponents: Array<CementComponent>
  plantId: number | string
}

export enum CompositionChangeType {
  Date = 'date',
  NewCement = 'newCement',
}

export interface CementTableData {
  id: string | number
  cementData: string | number
}

export interface EditMixAssociationOptions {
  mixDesignId: string
  mixCode: string
  baseLineCO2: string
  baseLineCementReduction: string
}
export interface MixAssociations {
  baseLineCO2: number | null
  baseLineCementReduction: number | null
  isActiveMixGroup: boolean
  isArchived: boolean
  isCO2Design: boolean
  mixCode: string
  mixDesignId: number
  plantId: number
}

export interface PlantDataFilterSectionExpanded {
  customer: boolean
  missingInformation: boolean
  cementType: boolean
  isMetric: boolean
  plantsWithoutInstalls: boolean
}

export interface AlarmsManagementFilterSectionExpanded {
  filterBy: boolean
  alarms: boolean
  serviceCases: boolean
  systemData: boolean
  injectionGraphs: boolean
}

export interface AddDataFilterSectionExpanded {
  customer: boolean
  mixDetails: boolean
  samples: boolean
}

export type PlantDataFilterProperty =
  | 'customer'
  | 'missingInformation'
  | 'cementType'
  | 'isMetric'
  | 'plantsWithoutInstalls'
  | 'state'
  | 'tse'

export type PlantDataFilterSectionCategory =
  | 'customer'
  | 'missingInformation'
  | 'cementType'
  | 'isMetric'

export type AlarmsManagementFilterProperty =
  | 'location'
  | 'dateRange'
  | 'alarms'
  | 'serviceCases'
  | 'systemData'
  | 'injectionGraphs'

export type AlarmsManagementSectionCategory =
  | 'filterBy'
  | 'alarms'
  | 'serviceCases'
  | 'systemData'
  | 'injectionGraphs'

export type AddDataFilterProperty =
  | 'producer'
  | 'mixCode'
  | 'condition'
  | 'showArchived'
  | 'samples'

export type AddDataSectionCategory = 'customer' | 'mixDetails' | 'samples'

export type ReportLibraryProperty =
  | 'producer'
  | 'mixGroup'
  | 'createdBy'
  | 'status'

export enum PlantDataFilterSectionName {
  customer = 'CUSTOMER',
  missingInformation = 'MISSING INFO',
  cementType = 'CEMENT TYPE',
  measurement = 'MEASUREMENT',
}

export interface FilterPanelSection {
  name: PlantDataFilterSectionName | ViewDesignsFilterSectionName
  category: PlantDataFilterSectionCategory
  filters: Array<Filter>
}

export type viewDesignsClickHandler = () => IMixGroupVariationTableSettings

export interface CopyVariationHeadCell {
  id: string
  numeric: boolean
  disablePadding: boolean
  label: string
  width: number | string
}

export interface AddDataHeadCell {
  id: string
  numeric?: boolean
  name: string
  sortable?: boolean
  width: string
  xxlWidth: string
  wordBreak?: boolean
}

export interface ViewDesignsHelperVariables {
  analysisFilterSettings: {
    mixCode: Array<string>
    producer: Array<string>
    supplier: Array<string>
    commissioning: Array<CommissioningFilterOptions>
  }
  setAnalysisFilterSettings: () => void
  rows: Array<DigestedMixDesign>
}

export interface PlantDataHelperVariables {
  isChipActive: (arg1: string) => boolean
}

export interface AddDataHelperVariables {
  mixCodeInternal: string
  setMixCodeInternal: () => string
  conditionInternal: string
  setConditionInternal: () => string
  inputsAlwaysEnabled: boolean
  minSamples: string
  maxSamples: string
  setMinSamples: string
  setMaxSamples: string
  sliderValues: Array<any>
  setSliderValues: (arg1: Array<string>) => void
}

export interface EchoHelperVariables {
  corpData: Array<any>
  filteredCorpData: Array<any>
  zipCodeError: boolean
  setZipCodeError: () => boolean
  clearAllFilters: () => void
  submitProjectSearch: () => void
  filterGroup: Array<any>
  filterSelect: boolean
}

export interface ViewDesignsFilterSectionExpanded {
  customer: boolean
  mixDetails: boolean
}

export type ViewDesignsFilterProperty =
  | 'producer'
  | 'supplier'
  | 'commissioning'
  | 'mixCode'
  | 'showArchived'

export type ViewDesignsFilterSectionCategory = 'customer' | 'mixDetails'

export enum ViewDesignsFilterSectionName {
  customer = 'CUSTOMER',
  mixDetails = 'MIX DETAILS',
}
export interface AddDataSettings {
  divisionId: string[]
  plantId: string[]
  mixCode: string[]
  isCO2Design: string[]
  minNumSamples: string[]
  maxNumSamples: string[]
  mixDesignId: string[]
  showArchived: string[]
  includeOnlyMissingDesignStrength: string[]
  includeOnlyMissingMixAssociation: string[]
}

export enum AddDataMode {
  view = 'addDataViewMode',
  edit = 'addDataEditMode',
}

export enum CommissionReportMode {
  view = 'commissionReportViewMode',
  edit = 'commissionReportEditMode',
}

export enum MaterialManagerComponent {
  AddMaterial = 'AddMaterial',
  EditMaterial = 'EditMaterial',
  MaterialTable = 'MaterialTable',
}

export enum MaterialRequestType {
  Material = 'AddMaterial',
  Alias = 'EditMaterial',
  Merge = 'MergeMaterial',
}

// IMaterial may not have all values defined when GET/POSTing to Kelowna
export interface IMaterial {
  id?: number | string
  divisionId: number | string
  divisionName?: string
  materialId?: number | string
  primaryMaterialType: string
  materialType: string
  materialMappingId?: number | null
  isIngested: boolean
  specificGravity: number | null
  supplierCompany: string | null
  supplierPlant: string | null
  cementSupplier?: CementPlantMetaData | null
  admixtureSolidsContentPercent: number | null
  aggregateManufacturing: string | null
  aggregatePitId: string | null
  aggregateRockType: string | null
  aggregateSize: string | null
  fiberManufacturing: string | null
  fiberSize: string | null
  fiberDiameterMm: number | null
  fiberLengthMm: number | null
  flyAshLossOfIgnitionPercent: number | null
  slagGrade: string | null
  slagProcessing: string | null
  waterTemperature: string | null
  modeOfTransportation: string | null
  aliases: Array<string> | null
  overrideSimilarCheck?: boolean
  currentPlantCompositions?: CurrentPlantCompositions[]
  [key: string]: any //for rendering dynamic metaData
}

export interface IMaterialExisting {
  MaterialMappingId: number
  DivisionId: number
  DivisionName: string
  IsIngested: boolean
  PrimaryMaterialType: string
  MaterialType: string
  SpecificGravity: number
  SupplierCompany: string
  SupplierPlant: string
  CementSupplier: string
  CurrentPlantCompositions?: CurrentPlantCompositions[]
  AdmixtureSolidsContentPercent: number
  AggregateManufacturing: string
  AggregatePitId: string
  AggregateRockType: string
  AggregateSize: string
  FiberManufacturing: string
  FiberSize: string
  FiberDiameterMm: number
  FiberLengthMm: number
  FlyAshLossOfIgnitionPercent: number
  SlagGrade: string
  SlagProcessing: string
  WaterTemperature: number
  ModeOfTransportation: string
  Aliases: string[]
}

export interface IDigestedMaterial {
  aliases: Array<string>
  cementSupplier: string
  isIngested: boolean
  materialId: number
  materialMappingId: number
  materialType: string
  primaryMaterialType: string
  specificGravity: number
  supplierCompany: string
  supplierPlant: string
  frontendName: string
  name: string
  title: string
  columnNumber: number
  isDefaultCement: boolean
}

export interface IDigestedMaterial {
  aliases: Array<string>
  cementSupplier: null | string
  columnNumber: number
  frontendName: string
  isDefaultCement: boolean
  isDefaultCO2: boolean
  isIngested: boolean
  materialId: number
  materialMappingId: number
  materialType: string
  name: string
  primaryMaterialType: string
  specificGravity: null | number
  supplierCompany: string
  supplierPlant: null | string
  title: string
}

export interface IAliasUpdate {
  divisionId?: number
  materialMappingId: number
  aliasName: string
}

export interface IMergeMaterial {
  sourceMappingId: number
  targetMappingId: number
}

export interface IMaterialForDownload {
  aliases?: Array<string>
  isIngested: boolean
  materialType?: string
  primaryMaterialType?: string
  specificGravity: number | null
  supplierCompany: string | null
  supplierPlant: string | null
}

export interface IMaterialCard extends IMaterial {
  [key: string]: number | string | null | Array<string> | undefined | boolean
}

export enum MaterialTableTooltips {
  LinkedMixDesigns = 'Mixes with this material',
  MergeMaterial = 'Merge Material',
}

export interface IMaterialTable {
  count: number
  results: Array<IMaterial>
}

export interface IMetaData {
  materialTypeMapping: MaterialObject
  fields: IMaterialTypeMetadata[]
}

export interface IMaterialTypeMetadata {
  get?: any // for calling the .get function on Metadata map
  key: string
  displayName: string
  dataType: MaterialDataType
  applicableValues?: string[]
  applicablePrimaryMaterialType?: string | null
  kelownaKeyName?: string
  cementPlants?: CementPlantMetaData[]
}

export interface CementPlantMetaData {
  cementPlantId: number
  plantName: string | null
  companyName: string | null
}

export interface MaterialObject {
  [key: string]: any
}

export interface IManualPrompt {
  show: boolean
  view: string
}

export type HandleChangeEvent =
  | ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  | ChangeEvent<{}>

export type HandleChangeValue = MaterialObject | string | null | undefined

export enum MaterialObjectKeys {
  DivisionName = 'divisionName',
  DivisionId = 'divisionId',
  PrimaryMaterialType = 'primaryMaterialType',
  MaterialType = 'materialType',
  SpecificGravity = 'specificGravity',
  SupplierCompany = 'supplierCompany',
  SupplierPlant = 'supplierPlant',
  CementSupplier = 'cementSupplier',
  AdmixtureSolidsContentPercent = 'admixtureSolidsContentPercent',
  AggregateManufacturing = 'aggregateManufacturing',
  AggregatePitId = 'aggregatePitId',
  AggregateRockType = 'aggregateRockType',
  AggregateSize = 'aggregateSize',
  FiberManufacturing = 'fiberManufacturing',
  FiberSize = 'fiberSize',
  FiberDiameterMm = 'fiberDiameterMm',
  FiberLengthMm = 'fiberLengthMm',
  FlyAshLossOfIgnitionPercent = 'flyAshLossOfIgnitionPercent',
  SilicaFumeGrade = 'silicaFumeGrade',
  SlagGrade = 'slagGrade',
  SlagProcessing = 'slagProcessing',
  WaterTemperature = 'waterTemperature',
  ModeOfTransportation = 'modeOfTransportation',
  Aliases = 'aliases',
}

export enum MaterialKey {
  DIVISIONAME = 'DivisionName',
  DIVISIONID = 'DivisionId',
  PRIMARYMATERIALTYPE = 'PrimaryMaterialType',
  MATERIALTYPE = 'MaterialType',
  SPECIFICGRAVITY = 'SpecificGravity',
  SUPPLIERCOMPANY = 'SupplierCompany',
  SUPPLIERPLANT = 'SupplierPlant',
  CEMENTSUPPLIER = 'CementSupplier',
  ADMITURESOLIDSCONTENTPERCENT = 'AdmixtureSolidsContentPercent',
  AGGREGATEMANUFACTURING = 'AggregateManufacturing',
  AGGREGATEPITID = 'AggregatePitId',
  AGGREGATEROCKTYPE = 'AggregateRockType',
  AGGREGATESIZE = 'AggregateSize',
  FIBERMANUFACTURING = 'FiberManufacturing',
  FIBERSIZE = 'FiberSize',
  FIBERDIAMETERMM = 'FiberDiameterMm',
  FIBERLENGTHMM = 'FiberLengthMm',
  FLYASHLOSSOFIGNITIONPERCENT = 'FlyAshLossOfIgnitionPercent',
  SILICAFUMEGRADE = 'SilicaFumeGrade',
  SLAGGRADE = 'SlagGrade',
  SLAGPROCESSING = 'SlagProcessing',
  WATERTEMPERATURE = 'WaterTemperature',
  MODEOFTRANSPORTATION = 'ModeOfTransportation',
  ALIASES = 'Aliases',
}

export enum MaterialType {
  ADMIXTURE = 'Admixture',
  AGGREGATE = 'Aggregate',
  CEMENT = 'Cement',
  CO2 = 'CO2',
  FIBER = 'Fiber',
  FLYASH = 'FlyAsh',
  RECLAIMEDWATER = 'ReclaimedWater',
  SLAG = 'Slag',
  SILICAFUME = 'SilicaFume',
  WATER = 'Water',
  SCM = 'SCM',
}

// Not a duplicate of the enum above. Used for options labels.
export enum MaterialTypeOptions {
  Admixture = 'Admixture',
  Aggregate = 'Aggregate',
  Cement = 'Cement',
  CO2 = 'CO2',
  Fiber = 'Fiber',
  FlyAsh = 'Fly Ash',
  ReclaimedWater = 'Reclaimed Water',
  Slag = 'Slag',
  SilicaFume = 'Silica Fume',
  Water = 'Water',
  SCM = 'SCM',
}

export enum MaterialSubTypeOptions {
  Cement = 'Cement',
  CementTypeI = 'Cement Type I',
  CementTypeIS = 'Cement Type IS',
  CementTypeIP = 'Cement Type IP',
  CementTypeIL = 'Cement Type IL',
  CementTypeII = 'Cement Type II',
  CementTypeI_II = 'Cement Type I/II',
  CementTypeI_IIMH = 'Cement Type I/IIMH',
  CementTypeI_II_LA = 'Cement Type I/II/LA',
  CementTypeIII = 'Cement Type III',
  CementTypeIV = 'Cement Type IV',
  CementTypeII_V = 'Cement Type II/V',
  CementTypeN = 'Cement Type N',
  CementTypeS = 'Cement Type S',
  CementCemI = 'Cement Cem I',
  CementCemII = 'Cement Cem II',
  CementCemIII = 'Cement Cem III',
  CementCemIV = 'Cement Cem IV',
  CementCemV = 'Cement Cem V',
  CementCemVI = 'Cement Cem VI',
  CementBlendedHydraulic = 'Cement Blended Hydraulic',
  CoarseAggregate = 'Coarse Aggregate',
  IntermediateAggregate = 'Intermediate Aggregate',
  FineAggregate = 'Fine Aggregate',
  Fiber = 'Fiber',
  FiberTypeI = 'Fiber Type I',
  FiberTypeII = 'Fiber Type II',
  FiberTypeIII = 'Fiber Type III',
  FiberTypeIV = 'Fiber Type IV',
  Water = 'Water',
  CO2 = 'CO2',
  SCM = 'SCM',
  Slag = 'Slag',
  SlagFerrous = 'Slag Ferrous',
  SlagNonFerrous = 'Slag Non-Ferrous',
  SlagFerroalloy = 'Slag Ferroalloy',
  FlyAsh = 'Fly Ash',
  FlyAshC = 'Fly Ash C',
  FlyAshF = 'Fly Ash F',
  FlyAshN = 'Fly Ash N',
  SilicaFume = 'Silica Fume',
  ReclaimedWater = 'Reclaimed Water',
  Admixture = 'Admixture',
  AirEntrainer = 'Air Entrainer',
  Retarder = 'Retarder',
  SuperPlasticizer = 'Super Plasticizer',
  WaterReducer = 'Water Reducer',
  HighRangeWaterReducer = 'High Range Water Reducer',
  MidRangeWaterReducer = 'Mid Range Water Reducer',
  LowRangeWaterReducer = 'Low Range Water Reducer',
  ViscosityModifier = 'Viscosity Modifier',
  HydrationStabilizer = 'Hydration Stabilizer',
  CorrosionInhibitor = 'Corrosion Inhibitor',
  ShrinkageReducer = 'Shrinkage Reducer',
  Accelerator = 'Accelerator',
  NonChlorideAccelerator = 'Non-Chloride Accelerator',
  ChlorideAccelerator = 'Chloride Accelerator',
  StrengthEnhancer = 'Strength Enhancer',
}

export enum MaterialDataType {
  NUMERIC = 'Numeric',
  ENUM = 'Enum',
  STRING = 'String',
  CEMENTPLANT = 'Object',
}

export enum MaterialExceptionType {
  SUCCESS = 'Success',
  DUPLICATEALIAS = 'DuplicateAlias',
  DUPLICATEMAPPING = 'DuplicateMapping',
  SIMILARMAPPING = 'SimilarMapping',
  ERROR = 'Error',
}

export enum MergeMaterialType {
  MERGE = 'MergeMaterial',
  EDIT = 'EditMaterial',
  SUCCESS = 'Success',
}

export type MaterialTypesMetadataMap = Map<
  MaterialType,
  IMaterialTypeMetadata[]
>

export interface IAtomCurrentCustomer {
  division: IAtomDivision | null
  plant: IAtomPlant | null
}

export interface IAtomDivision {
  divisionId: number
  name: string
  isImperial: boolean
}

export interface IAtomPlant {
  plantId: number
  name: string
}

export enum MaterialModeOfTransportation {
  Truck = 'Truck',
  Rail = 'Rail',
  Barge = 'Barge',
  Plane = 'Plane',
}

export type AnalysisFilterProperty =
  | 'customer'
  | 'materialType'
  | 'materialSubtype'
  | 'supplierCompany'
  | 'supplierPlant'
  | 'showOnlyIngested'

export interface MaterialManagerFilterSectionExpanded {
  customer: boolean
  materialDetails: boolean
}

export enum CopyVariationTableType {
  AddData = 'AddData',
  Analysis = 'Analysis',
}

export type BaleenIntervals =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '48'
  | '72'
  | '120'
  | '144'
  | '168'
  | '336'
  | '504'
  | '672'
  | '1344'
  | '1440'
  | '4320'
  | '8760'

export type NumericIntervals =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 48
  | 72
  | 120
  | 144
  | 168
  | 336
  | 504
  | 672
  | 1344
  | 1440
  | 4320
  | 8760

export interface IMaturityIntervalOptions {
  label: string
  value: number
}

export type KelownaBaleenStrengths = {
  [key in BaleenIntervals]?: {
    cylinderCount: number
    averageMpa: number
  }
}

export type BaleenStrengths = {
  [key in BaleenIntervals]?: {
    cylinderCount: number
    strength: number
  }
}

export type BaleenCementEfficiency = {
  [key in BaleenIntervals]?: number
}
export interface BaleenSample {
  batchId: number
  ticketId: string
  variationId: string
  variationIdLabel: string
  air: number | null
  slump: number | null
  cementContent: number | null
  cementEfficiency: BaleenCementEfficiency
  concreteTemperature: number | null
  densityKgPerM3: number | null
  cO2Dosage: number | null
  cO2DosageUnit: CO2DosageUnit
  cementReduction: number | null
  outlierType: BaleenOutlierType
  outlierReasonDropdown: string | null
  outlierReasonTextField: string | null
  outlierStatus: BaleenOutlierStatus
  strengths: BaleenStrengths
  cementType: string | null
  cementSupplier: string | null
  productionDate: Date | null
  scmPercent: number | null
  batchWaterCementRatio: number | null
  batchStrength?: number | null
  batchInterval?: string
}

export interface BaleenMixVariation {
  mixDesignId: number
  variationId: string
  variationIdLabel: string
  air: number | null
  slump: number | null
  cementContent: number | null
  cementEfficiency: BaleenCementEfficiency
  concreteTemperature: number | null
  cO2Dosage: number | null
  cO2DosageLabel: number | null // will be used in variation table and variation id label
  cO2DosageUnit: CO2DosageUnit
  cementReduction: number | null
  cementReductionLabel: number | null // will be used in variation table and variation id label
  minDate?: Date | null
  maxDate?: Date | null
  strengths: {
    [key in BaleenIntervals]?: number
  }
  densityKgPerM3: number | null
  samples: {
    [key: number]: BaleenSample
  }
  variationType: VariationTypes | null
  fillColor: string
  haloColor: string
}

export interface BaleenModalData {
  batchId: number
  variationId: string
  outlierType: BaleenOutlierType
  outlierReasonDropdown: string | null
  outlierStatus: BaleenOutlierStatus
}

export type BaleenOutlierType = 'Orca' | 'ACI2A1' | 'Interval' | null

export type BaleenOutlierStatus =
  | 'Sample'
  | 'PotentialOutlierChecked'
  | 'PotentialOutlierUnchecked'
  | 'Outlier'

export interface KelownaBaleenSample {
  batchSampleId: number
  externalTicketId: string
  airContent: number | null
  slumpMm: number | null
  concreteTemperature: number | null
  ambientTemperature: number | null
  batchWaterCementRatio: number | null
  productionDate: string
  cO2Dosage: number | null
  cO2DosageUnit: CO2DosageUnit // this may be wrong?? based on swagger
  cementReduction: number | null
  averageStrength7Days: number | null
  averageStrength28Days: number | null
  densityKgPerM3: number | null
  cementContentKgPerM3: number | null
  strengthReadings: [
    {
      batchStrength: number
      batchInterval: number
    }
  ]
  notes: string
  testCategory: TestCategoriesKey
  hourlyStrengthAvg: KelownaBaleenStrengths
  outlierType?: BaleenOutlierType
  outlierReason?: string
  cementitiousEfficiency: number | null
  cementType: string | null
  cementSupplier: string | null
  scmPercent: number | null
}

export interface KelownaBaleenMixVariation {
  averageStrengthMpa: number | null
  requiredStrengthMpa: number | null
  overDesign: number | null
  cementitiousEfficiency: number | null
  cementReductionPercent: number | null
  cO2Dosage: number | null
  cO2DosageUnit: CO2DosageUnit | null
  variationType: VariationTypes | null
  sampleCount: number | null
  cylinderCount: number | null
  samples: KelownaBaleenSample[]
}
export interface KelownaBaleenMixGroup {
  averageStrengthMpa: number
  requiredStrengthMpa: number
  overDesign: number
  cementitiousEfficiency: number
  mixDesignId: number
  divisionId: number
  divisionName: string
  isInCommissioning: true
  plantId: number
  plantName: string
  mixCode: string
  timestamp: string
  preCCMixCode: string
  isCO2Design: true
  cementPlantId: number
  cementSupplier: string
  isActiveMixGroup: true
  parentMixDesignId: number
  cementReductionPercent: number
  cO2Dosage: number
  waterCementRatio: number
  designStrengthMpa: number
  strengthIntervalDays: number | null
  cementitiousContentKgPerM3: number
  scmContentKgPerM3: number
  scmPercent: number
  yieldUnitless: number
  mixVariations: KelownaBaleenMixVariation[]
  cementTypes: string[] | null
  cementContent: number
  slumpMmMin: number
  slumpMmMax: number
  airContentPercentageMin: number
  airContentPercentageMax: number
}

export type BaleenFilterProperty =
  | 'producer'
  | 'mixGroup'
  | 'mixVariation'
  | 'interval'
  | 'isMetric'
  | 'dataColumns'
  | 'yAxis'

export type BaleenYAxis =
  | 'air'
  | 'slump'
  | 'concreteTemperature'
  | 'batchStrength'
  | 'cementContent'
  | 'cO2Dosage'
  | 'batchWaterCementRatio'

export enum BaleenOutlierReasons {
  InconsistentCementitiousLoading = 'Inconsistent cementitious loading',
  InconsistentNoncementitiousLoading = 'Inconsistent noncementitious loading',
  UnexpectedSlump = 'Unexpected slump',
  UnexpectedAirContent = 'Unexpected air content',
  UnexpectedConcreteTemperature = 'Unexpected concrete temperature',
  CuringConditions = 'Curing conditions',
  Other = 'Other',
}

export interface APIResponse {
  ok: boolean
  status: number
  data?: MaterialObject
  json?: Function | undefined
}

export enum MaterialSelectType {
  AddMix = 'AddMix',
  AddBatch = 'AddBatch',
  MergeMaterial = 'MergeMaterial',
}

export enum DialogModalType {
  info = 'info',
  warning = 'warning',
  success = 'success',
  error = 'error',
}

export enum DialogModalSize {
  XSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  XLarge = 'xl',
}

export interface ICompareAndAnalyzeMixDesigns {
  [key: number]: DigestedMixDesign
}

export enum PropertyUnit {
  DegreeCelsius = '°C',
  DegreeFahrenheit = '°F',
  KgPerM3 = 'kg/m\u00B3',
  LbPerYd3 = 'lb/yd\u00B3',
  MPaM3PerKg = 'MPa*m\u00B3/kg',
  PsiYd3PerLb = 'psi*yd\u00B3/lb',
  MilliLitrePerM3 = 'mL/m\u00B3',
  OzPerYd3 = 'oz/yd\u00B3',
}
export enum AddDefaultMaterialsType {
  AddMix = 'AddMix',
  AddBatch = 'AddBatch',
}

export type fetchMaterialConstraints = {
  Limit: number
  Offset: number
  SortColumn: string
  SortOrder: string
  divisionId?: number
  primaryMaterialType?: string
  materialType?: string
  supplierCompany?: string
  supplierPlant?: string
  isOrcaUpdated?: boolean
}

export type CommissionReportStatusKey = 'Draft' | 'PendingReview' | 'Reviewed'

export type CommissionReportTestingStageKey = 'ReadyMix' | 'Precast'

export enum CommissionReportStatus {
  Draft = 'Draft',
  PendingReview = 'Pending Review',
  Reviewed = 'Reviewed',
}

export enum TestingStage {
  TestingComplete = 'Testing Complete',
  NeedsMoreTesting = 'Needs More Testing',
}

export enum PlantType {
  ReadyMix = 'Ready Mix',
  Precast = 'Precast',
}

export type TestResultsPropertyOptionType =
  | 'strength'
  | 'standardDeviation'
  | 'freshProperty'

export interface IUndigestedLibraryReport {
  commissioningReportId: number
  createdBy: string
  divisionId: number
  divisionName: string
  isMetric: boolean
  lastUpdatedTimestamp: string
  mixCodes: string[]
  reviewedTimestamp: string | null
  status: CommissionReportStatusKey
  createdByUserId: number
}

export interface ILibraryReport {
  divisionName: string
  reportId: number
  createdBy: string
  mixCodes: string[]
  status: CommissionReportStatus
  lastUpdated: string
  reviewedDate?: string
  isMetric: boolean
}

export interface ICreateReportSettings {
  divisionId: string[]
  plantId: string[]
  mixGroup: string[]
}

export interface IMixGroupOptionWithConditions {
  id: number | string
  name: string
  mixCode: string
  baseLineCementReduction: number | string
  baseLineCO2: number | string
}

export interface IMixDesignDetailsSettings {
  readonly designStrength: boolean
  waterCementRatio: boolean
  airContentRange: boolean
  slumpRange: boolean
  scmPercent: boolean
  cementTypes: boolean
  cementContent: boolean
  [key: string]: boolean
}

export interface ITestResultsPropertyOption {
  id: string
  kelownaId: testResultPropertiesKelownaId | NumericIntervals
  label: string
  labelWithUnit: string
  type: TestResultsPropertyOptionType
}

export interface ICommissionReportTestResultsSettings {
  selectedProperties: testResultPropertiesKelownaId[]
  selectedStrengthHours: NumericIntervals[]
  selectedStrengthStDevHours: NumericIntervals[]
  showDifference: boolean
}

export interface ICommissionReportFrequencyGraphSettings {
  isSelected: boolean
  selectedInterval: NumericIntervals
  showFailureZone: boolean
  showHistogram: boolean
  showBellCurve: boolean
}

export interface ICommissionReportAvgStrengthGraphSettings {
  isSelected: boolean
  selectedInterval: NumericIntervals[]
  showDesignStrength: boolean
}

export interface IOutlierSettings {
  show: boolean
  showReason: boolean
}

export interface ICommissionReportVariationSettings {
  selectedTestCategories: TestCategoriesKey[]
  cO2Dosage: number | null
  cO2DosageUnit: CO2DosageUnit | null
  cementReductionPercent: number | null
}
export interface IReportMixSelectionSettings {
  mixDesignId: number
  mixDesignPropertiesIncludedOnCustomerReport: IMixDesignDetailsSettings
  testResults: ICommissionReportTestResultsSettings
  frequencyGraph: ICommissionReportFrequencyGraphSettings
  avgStrengthGraph: ICommissionReportAvgStrengthGraphSettings
  outlierSettings: IOutlierSettings
  baselineVariation?: ICommissionReportVariationSettings
  carbonCureVariation?: ICommissionReportVariationSettings
}

export interface KelownaCommissionReportSettings {
  commissioningReportId: number
  conclusionParagraph: string
  createdBy: string
  createdByUserEmail: string
  createdByUserId: number
  createdTimestamp: string
  divisionId: number
  isMetric: boolean
  lastEditedBy: string
  lastEditedByUserId: number
  lastEditedTimestamp: string
  mixSelection: KelownaMixSelection[]
  plantId: number
  plantType: string
  reviewedTimestamp: string | null
  status: CommissionReportStatusKey
  testingStage: CommissionReportTestingStageKey
  testingSummary: string
}

export interface ICommissionReportSaveReqBody {
  conclusionParagraph: string
  divisionId: number
  mixSelection: KelownaMixSelection[]
  plantId: number
  status: CommissionReportStatusKey
  testingStage: CommissionReportTestingStageKey
  testingSummary: string
}

export interface KelownaBaselineVariation {
  selectedTestCategories: TestCategoriesKey[]
  cO2Dosage: number
  cO2DosageUnit: CO2DosageUnit
  cementReductionPercent: number
}

export interface KelownaCarbonCureVariation {
  selectedTestCategories: TestCategoriesKey[]
  cO2Dosage: number
  cO2DosageUnit: CO2DosageUnit
  cementReductionPercent: number
}

export interface KelownaMixSelection {
  baselineVariation: KelownaBaselineVariation
  carbonCureVariation: KelownaCarbonCureVariation
  mixDesignId: number
  selectedStrengthHours: NumericIntervals[]
  selectedStrengthStDevHours: NumericIntervals[]
  settings: {
    avgStrengthGraphSettings: {
      isSelected: boolean
      selectedIntervalHours: NumericIntervals[]
      showDesignStrength: boolean
    }
    frequencyGraphSettings: {
      isSelected: boolean
      selectedIntervalHours: NumericIntervals
      showFailureZone: boolean
      showHistogram: boolean
      showBellCurve: boolean
    }
    propertiesToInclude: {
      includeAirContentRange: boolean
      includeCementContent: boolean
      includeCementType: boolean
      includeDesignStrength: boolean
      includeScmPercent: boolean
      includeSlump: boolean
      includeWaterCementRatio: boolean
    }
    showOutlierReason: boolean
    showOutliers: boolean
    showDifference: boolean
  }
  testResultProperties: string[] //Each string will be an ID
}

export interface ICommissionReportSettings {
  producerId: number
  plantId: number
  isMetric: boolean
  reportStatus: CommissionReportStatusKey
  testingStage: CommissionReportTestingStageKey
  lastUpdated: Date | string
  createdBy: string
  createDate: Date | string
  createdByUserEmail: string
  lastUpdatedBy: string
  plantType: PlantType
  testingSummary: string
  conclusionParagraph: string
  mixDesignSettings: IReportMixSelectionSettings[]
}

export enum MixSelectionInputType {
  Autocomplete = 'autocomplete',
  Multiselect = 'multiselect',
}

export enum MixSelectionLabels {
  MixGroup = 'Mix Group',
  MixCode = 'Mix Code',
  Baseline = 'Baseline',
  CarbonCure = 'CarbonCure',
  TestCategory = 'Test Categories',
  Categories = 'Categories',
  MixVariation = 'Variation ID',
}

export type SummaryMixSelectionData = {
  mixCode: string
  designStrength: number
  designStrengthUnit: string
  baselineCemAdjustment: number
  baselineCO2Dosage: number
  carboncureCemAdjustment: number
  specifiedMaturityAge: number
}

export type SummarySingleData = {
  corporationName: string
  locationName: string
  customerName: string
  mixCode: string
  designStrength: number
  strengthUnit: string
  carboncureCemAdjustment: number
  carboncureCO2Dosage: number
  carboncureCO2DosageUnit: string
  specifiedMaturityAge: number
  baselineStrength: number
  carboncureStrength: number
}

export type SummaryMultipleData = {
  customerName: string
  locationName: string
  mixAmount: number
  mixCodes: string
}

export type SummaryData = SummarySingleData | SummaryMultipleData

export enum SummaryTestingType {
  Single,
  Multiple,
}

export interface ICommissionReportVariationOption {
  id: string
  label: string
}

export interface IMixSelection {
  mixDesignId: number
  mixCode: string
  designStrength: number | null
  strengthIntervalHours: number | null
  waterCementRatio: number | null
  airContentRange: string | null
  slumpRange: string | null
  scmPercent: number | null
  cementTypes: string[] | null
  cementContent: number | null
  testResultsPropertyOptions: ITestResultsPropertyOption[]
  variationOptions: ICommissionReportVariationOption[]
  digestedVariations: ICommissionReportVariation[]
  intervalOptions: NumericIntervals[]
  specifiedMaturityAge: NumericIntervals | null
}

export interface ICommissionReportVariation {
  mixDesignId: number
  variationId: string
  variationIdLabel: string
  cO2Dosage: number | null
  cO2DosageLabel: string | null
  cO2DosageUnit: CO2DosageUnit | null
  cementReduction: number | null
  slump: number | null
  slumpStdDev: number | null
  airContent: number | null
  cementEfficiency: number | null
  unitWeight: number | null
  cementContent: number | null
  concreteTemperature: number | null
  strengths: ICommissionReportVariatonStrengths
  samples: ICommissionReportSample[]
  filteredSamples?: ICommissionReportSample[]
  airStdDev: number | null
  testCategoryOptions: TestCategoryOption[]
  variationType: VariationTypes | null
  orcaVariationType: VariationTypes | null
  [key: string]:
    | string
    | number
    | null
    | undefined
    | ICommissionReportSample[]
    | TestCategoryOption[]
    | ICommissionReportVariatonStrengths
}

export interface TestCategoryOption {
  id: TestCategoriesKey | 'all'
  label: string
}
export interface ICommissionReportSample {
  mixDesignId: number
  batchId: number
  ticketId: string
  variationId: string
  variationIdLabel: string
  cO2Dosage: number | null
  cO2DosageUnit: string | null
  cementReduction: number | null
  slump: number | null
  airContent: number | null
  cementEfficiency: number | null
  unitWeight: number | null
  cementContent: number | null
  temperature: number | null
  strengths: { [key: number]: number }
  isOutlier: boolean
  outlierReason: string | null
  testCategory: TestCategoriesKey | null
}

export interface StrengthWithStDev {
  strength: number
  stDev: number
}

export interface ICommissionReportVariatonStrengths {
  [key: number]: { strength: number; stDev: number | null }
}

export type MappedCommissionReportVariationStrengths = {
  [key in NumericIntervals]: number
}

export interface KelownaSampleStrengths {
  [key: string]: {
    cylinderCount: number
    averageMpa: number
  }
}

export interface ICommissionReportSampleStrengths {
  [key: number]: number
}

export type MappedCommissionReportSampleStrengths = {
  [key in NumericIntervals]: number
}

export interface CommissionReportOutlierRow {
  id: string
  description: string
  reason: string | null
}

export interface ICommissionReportAvgStrengthGraphSettings {
  isSelected: boolean
  selectedInterval: NumericIntervals[]
  showDesignStrength: boolean
}

export type testResultPropertiesKelownaId =
  | 'CO2Dose'
  | 'AverageSlump'
  | 'SlumpStandardDeviation'
  | 'AverageAir'
  | 'AirStandardDeviation'
  | 'ConcreteTemperature'
  | 'CementEfficiency'
  | 'AvgCementLoading'
  | 'UnitWeight'

export interface IViewMixSelectionProperty {
  id: string
  name: string
}

export type insufficientSamplesBannerType = 'default' | 'interval'

export interface IInsufficientVariationSamples {
  id: string
  label: string
}

export interface IInsufficientMaturityAgeSamples {
  [key: number]: string[]
}

export interface IUseGenerateCommissionReportPDF {
  history: RouteComponentProps['history']
  isPrinting: boolean
  pdfExportComponent: React.MutableRefObject<any>
  reportId: number | string
  isCommissionReportViewMode: boolean
  reviewedReportModalMixInfo: IReviewedReportModalMixInfo[]
  divisionName: string
  setIsPrinting: React.Dispatch<SetStateAction<boolean>>
  setReportSettings: React.Dispatch<SetStateAction<ICommissionReportSettings>>
  pdfTemplate: (context: any) => string
  setIsCommissionReportViewMode: React.Dispatch<SetStateAction<boolean>>
  setIsReviewErrorModalOpen: React.Dispatch<SetStateAction<boolean>>
}

export interface IReviewedReportModalMixInfo {
  mixCode: string
  mixDesignId: number
}

export interface IReviewedReportModal {
  divisionName: string
  reportId: number
  mixes: IReviewedReportModalMixInfo[]
}
